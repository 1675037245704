import React, { useContext, useState, useEffect } from 'react';
import { Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { collection, doc, setDoc, onSnapshot, query } from 'firebase/firestore';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { existsWithLength, isUndefined } from '@aldridge/aldg-helpers';
import { firebaseConfig, firestore } from '../firebase';
import QuickSearchToolbar from '../_helpers/QuickSearchToolbar';
import { UserContext } from '../providers/UserProvider';

const PreFabTeamList = () => {
  const user = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [updatePromises, setUpdatePromises] = useState([]);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  useEffect(() => {
    onSnapshot(query(collection(firestore, 'PreFabTeam')), (snap) => {
      const all = [];
      snap.forEach(d => all.push(d.data()));
      setUsers(all);
    });
  }, []);
  const apiRef = useGridApiRef();
  const columns = [
    {
      field: 'Lead',
      headerName: 'Lead',
      valueGetter: (params) => params.row.LeadDisplay?.split(' [')[0],
      flex: getFlex(25),
      width: getWidth(150),
      sortable: true,
      canEdit: true,
      inputType: 'InputAutocomplete',
      config: firebaseConfig,
      firestoreOptions: {
        valueKey: 'Email',
        collection: 'Employees',
        where: [['Status', '==', 'A']],
        orderBy: 'PreferredLastName',
        method: 'onSnapshot',
      },
      optionDisplay: (opt) =>
        `${opt.PreferredFirstName}${!isUndefined(opt.MiddleName) && opt.MiddleName !== '' ? ` ${opt.MiddleName}` : ''} ${opt.PreferredLastName} [${opt.Title}]` || '',
      autorecordFields: ['Email'],
    },
    {
      field: 'Email',
      headerName: 'Email',
      valueGetter: (params) => params.row.Email,
      flex: 20,
      hideable: false
    },
    {
      field: 'Role',
      headerName: 'Role',
      valueGetter: (params) => params.row.Role,
      flex: getFlex(9),
      width: getWidth(150),
      sortable: true,
      canEdit: true,
      inputType: 'InputSelect',
      options: [
        { label: 'Admin', value: 'Admin' },
        { label: 'Lead', value: 'Lead' },
        { label: 'PreFab', value: 'PreFab' },
      ]
    }
  ];

  useEffect(() => {
    updatePromises.forEach((uPromise) => {
      if (existsWithLength(uPromise.id)) {
        const { Lead, Email } = uPromise.row;
        if (Lead.length > 0) {
          const docRef = doc(firestore, `Users/${Email}`);
          const newRole = uPromise.operation === 'set' ? uPromise.row.Role : 'none';
          setDoc(docRef, { appRole: newRole, email: Email, dateModified: new Date() }, { merge: true });
        }
      }
    });
  }, [updatePromises]);

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <AldgDataGrid
            editable={user.admin}
            autoHeight
            FirebaseProps={{
              collection: "PreFabTeam",
              firebaseConfig,
            }}
            canDelete={user.admin}
            getRowId={(r) => r.id}
            components={{ Toolbar: QuickSearchToolbar }}
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={50}
            apiRef={apiRef}
            rows={users}
            columns={columns}
            updateOverride={updatePromises}
            setUpdateOverride={setUpdatePromises}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};


PreFabTeamList.propTypes = {};
PreFabTeamList.defaultProps = {};


export default PreFabTeamList;