import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Grid, Typography, FormHelperText } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { AldgDataGrid, InputAutocomplete, InputCheckbox, InputDate, InputRadio, InputSelect, InputText, InputTextarea } from '@aldridge/aldg-data-components';
import { NaNtoNum } from '@aldridge/aldg-helpers';
import { config } from '../../../firebase';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const OrderInformation = (props) => {
  const { request, handleChange, user, disabledForm, hasEditAccess } = props;

  const [updatePromises, setUpdatePromises] = useState([]);
  const pFormat = (val = 0) => `${val} %`;
  const columns = [
    {
      field: 'SubAssembly',
      flex: 20,
      valueGetter: (params) => params.row.SubAssembly || ''
    },
    {
      field: 'Hours',
      headerName: 'Planned Hours',
      canEdit: true,
      inputType: 'InputNumber',
      flex: 20,
      valueGetter: (params) => params.row.Hours || ''
    },
    {
      field: 'CompletedHours',
      headerName: 'Completed Hours',
      canEdit: true,
      inputType: 'InputNumber',
      flex: 20,
      valueGetter: (params) => params.row.CompletedHours || ''
    },
    {
      field: 'PercentComplete',
      headerName: "% Complete",
      canEdit: true,
      inputType: 'InputNumber',
      valueGetter: (params) => params.row.PercentComplete || '',
      valueFormatter: (params) => pFormat(params.value),
      flex: 20
    },
  ];

  useEffect(() => {
    if (updatePromises.length > 0) {
      const uRecord = JSON.parse(JSON.stringify(request));
      const ogRecords = JSON.parse(JSON.stringify(uRecord.data.SubAssemblies));
      const removeRecords = updatePromises.filter(r => r.operation !== 'set').map(r => r.id);
      const updateRecords = updatePromises.filter(r => r.operation === 'set').map(r => r.row);
      const newSet = ogRecords.filter(r => !removeRecords.includes(r.id));
      updateRecords.forEach(ur => {
        const idx = newSet.findIndex(ns => ns.id === ur.id);
        if (idx > -1) {
          newSet[idx] = ur;
        } else {
          newSet.push(ur);
        }
      });
      const newTotalPercent = newSet.length > 0 ? newSet.reduce((acc, cur) => acc + NaNtoNum(cur.PercentComplete), 0) / newSet.length : '';
      handleChange(newSet, 'SubAssemblies');
      handleChange(newTotalPercent, 'PercentComplete');
      setUpdatePromises([]);
    }
  }, [updatePromises]);

  const TotalPercentCompelete =
    request.data.SubAssemblies?.length > 0 ? request.data.SubAssemblies.reduce((acc, cur) => acc + NaNtoNum(cur.PercentComplete), 0) / request.data.SubAssemblies.length : '';
  ;
  console.log(!hasEditAccess, disabledForm, !request.data.isWorkOrder);
  return (
    <Card style={{ margin: '12px 0px' }}>
      <CardHeader title={`${['Draft', '1. Requested', 'Rejected'].indexOf(request.data.Status) > -1 ? 'Request ' : 'Order '} Information`} />
      <CardContent>
        <Grid container>
          <Grid item className='aldg-field' xs={12} md={6}>
            <InputSelect
              name='Status'
              label='Status'
              onChange={handleChange}
              value={request.data.Status || ''}
              options={[
                { value: '1. Requested', label: '1. Requested' },
                { value: '2. Awaiting Material', label: '2. Awaiting Material' },
                { value: '3. Awaiting Scheduling', label: '3. Awaiting Scheduling' },
                { value: '4. In Progress', label: '4. In Progress' },
                { value: '5. On Hold', label: '5. On Hold' },
                { value: '6. Complete', label: '6. Complete' },
                { value: 'Draft', label: 'Draft' },
                { value: 'Awaiting Shipment', label: 'Awaiting Shipment' },
                { value: 'Rejected', label: 'Rejected' },
                { value: 'Approved', label: 'Approved' },
              ]}
              disabled={!hasEditAccess || disabledForm || !request.data.isWorkOrder}
            />
          </Grid>
          <Grid item className='aldg-field' xs={12} md={6}>
            <InputText
              name="OrderNumber"
              value={request.data.OrderNumber || "Auto Generated on Approval"}
              disabled
              label="Order Number"
            />
          </Grid>
          {!user.admin && !user.lead ? null :
            <Grid item className='aldg-field' xs={12} md={6}>
              <InputText
                name="CreatedBy"
                value={request.CreatedBy || ''}
                disabled
                label="Created By"
              />
            </Grid>}
          {!user.admin && !user.lead ? null :
            <Grid item className='aldg-field' xs={12} md={6}>
              <InputText
                name="CreatedDate"
                value={request.CreatedDate !== '' ? `${dayjs(request.CreatedDate)
                  .tz('America/Chicago')
                  .format('llll')} CST` : ''}
                disabled
                label="Created Date"
              />
            </Grid>}
          {!user.admin && !user.lead ? null :
            <Grid item xs={12}>
              <Grid container>
                <Grid item className='aldg-field' xs={12}>
                  <InputText
                    name="PercentComplete"
                    value={request.data.PercentComplete || ''}
                    onChange={handleChange}
                    label="Percent Complete"
                    disabled={disabledForm}
                  /></Grid><Grid className='aldg-field' item xs={12}>
                  <FormHelperText variant='caption'>Last Updated: <br /> {request.data.PercentCompleteUpdated ? dayjs(request.data.PercentCompleteUpdated).tz('America/Chicago').format('lll') : ''}</FormHelperText>
                </Grid></Grid></Grid>}
          {(user.admin || user.lead) && <Grid item className='aldg-field' xs={12}><InputAutocomplete
            firebaseConfig={config}
            firestoreOptions={{
              method: 'onSnapshot',
              collection: "PreFabTeam",
              valueKey: "Email",
              where: [["Role", 'in', ['Lead', 'Admin']]]
            }}
            onChange={handleChange}
            name="PreFabLead"
            optionDisplay={['option_LeadDisplay']}
            label="PreFab Lead"
            value={(typeof request.data.PreFabLead !== 'undefined' && request.data.PreFabLead.length !== 0) ? request.data.PreFabLead : -1}
            filterDataset={(records) => {
              const filtered = records.filter((res) => res.Email !== '');
              return filtered;
            }}
            multiple
          /></Grid>}
          {(user.admin || user.lead) && <Grid item className='aldg-field' xs={12}><InputAutocomplete
            firebaseConfig={config}
            firestoreOptions={{
              method: 'onSnapshot',
              collection: "PreFabTeam",
              valueKey: "Email",
              where: [["Role", '==', 'PreFab']]
            }}
            onChange={handleChange}
            name="PreFabTeam"
            optionDisplay={['option_LeadDisplay']}
            label="PreFab Team"
            value={(typeof request.data.PreFabTeam !== 'undefined' && request.data.PreFabTeam.length !== 0) ? request.data.PreFabTeam : -1}
            filterDataset={(records) => {
              const filtered = records.filter((res) => res.Email !== '');
              return filtered;
            }}
            multiple
          /></Grid>}
          {!user.admin && !user.lead ? null :
            <><Grid item className='aldg-field' xs={3}>
              <InputCheckbox
                name="IsGLTicket"
                checkboxes={[
                  { value: 'true', label: 'Is this a G/L Ticket?' }
                ]}
                value={request.data.IsGLTicket?.toString() || ''}
                onChange={handleChange}
              />
            </Grid><Grid item xs={9} /></>}
          {!user.admin && !user.lead || request.data.IsGLTicket?.toString() !== 'true' ? null :
            <Grid item className='aldg-field' xs={12} md={6}>
              <InputAutocomplete
                firebaseConfig={config}
                name="DepartmentNumber"
                label="Department Number"
                firestoreOptions={{
                  method: 'onSnapshot',
                  collection: "Departments",
                  valueKey: "DepartmentNumber"
                }}
                onChange={handleChange}
                required
                disabled={!hasEditAccess || disabledForm}
                optionDisplay={['option_DepartmentNumber', ' - ', 'option_DepartmentName']}
                value={(typeof request.data.DepartmentNumber !== 'undefined' && request.data.DepartmentNumber.length !== 0) ? request.data.DepartmentNumber : -1}
              />
            </Grid>}
          {request.data.IsGLTicket?.toString() !== 'true' ? null :
            <Grid item className='aldg-field' xs={12} md={6} style={{ display: 'flex', width: '100%', alignItems: 'center', margin: '0' }}>
              <InputText
                onChange={handleChange}
                name="GLCode"
                value={request.data.GLCode || ''}
                // maskProps={{ mask: "999/9999", delim: '/' }}
                label='G/L Code'
                required
              />
            </Grid>}
          {request.data.IsGLTicket?.toString() === 'true' ? null :
            <Grid item className='aldg-field' xs={12} md={6}>
              <InputAutocomplete
                firebaseConfig={config}
                name="JobNumber"
                label="Job Number"
                firestoreOptions={{
                  method: 'onSnapshot',
                  collection: "Jobs",
                  where: [['JobStatus', '==', 'Open']],
                  orderBy: "JobNumber",
                  valueKey: "jctdscid"
                }}
                value={(typeof request.data.JobNumber !== 'undefined' && request.data.JobNumber.length !== 0) ? request.data.JobNumber : -1}
                onChange={handleChange}
                optionDisplay={(opts) => `${opts.JobNumber}.${opts.SubJobNumber} - ${opts.JobDescription} [Division: ${opts.Division}]`}
                required
                disabled={!hasEditAccess || disabledForm}
              />
            </Grid>}
          {request.data.IsGLTicket?.toString() === 'true' ? null :
            <Grid item className='aldg-field' xs={12} md={6}>
              <InputAutocomplete
                firebaseConfig={config}
                firestoreOptions={{
                  method: 'onSnapshot',
                  collection: "Piccs",
                  where: [['Status', '==', 'Active'], ['jctdscid', '==', (request.data.JobNumber.length > 0) ? request.data.JobNumber[0] : -1]],
                  orderBy: "PICC",
                  valueKey: "jctmstid"
                }}
                onChange={handleChange}
                name="Picc"
                optionDisplay={['option_PICC', ' - ', 'option_Description']}
                label="PICC"
                value={(typeof request.data.Picc !== 'undefined' && request.data.Picc.length !== 0) ? request.data.Picc : -1}
                // requirement={request.data.JobNumber}
                required
                disabled={!hasEditAccess || disabledForm}
              />
            </Grid>}
          <Grid item xs={12} className='aldg-field'>
            <InputAutocomplete
              firebaseConfig={config}
              firestoreOptions={{
                method: 'onSnapshot',
                collection: "Employees",
                where: [['Status', '==', 'A']],
                orderBy: "PreferredLastName",
                valueKey: "Email"
              }}
              onChange={handleChange}
              name="SharedWith"
              optionDisplay={['option_PreferredFirstName', ' ', 'option_MiddleName', ' ', 'option_PreferredLastName', ' ', 'option_Suffix', '[', 'option_Title', ']']}
              label="Shared With"
              value={(typeof request.SharedWith !== 'undefined' && request.SharedWith.length !== 0) ? request.SharedWith : -1}
              disabled={!hasEditAccess || disabledForm}
              filterDataset={(records) => {
                const filtered = records.filter((res) => res.Email !== '');
                return filtered;
              }}
              required
              multiple
            />
          </Grid>
          <Grid item className='aldg-field' xs={12} md={6}>
            <InputDate
              onChange={handleChange}
              name="DateNeededBy"
              value={request.data.DateNeededBy || ''}
              label="PreFab Completed By"
              disabled={!hasEditAccess || disabledForm}
            />
          </Grid>
          <Grid item className='aldg-field' xs={12} md={6}>
            <InputSelect
              name="TypeOfWork"
              label="Type of Work"
              value={request.data.TypeOfWork || ''}
              onChange={handleChange}
              options={[
                { label: 'Welding/ Fabrication/ Jigs', value: 'Welding/ Fabrication/ Jigs' },
                { label: 'Underground/ Ductbank', value: 'Underground/ Ductbank' },
                { label: 'Cable/ Wire Prep', value: 'Cable/ Wire Prep' },
                { label: 'Building Lighting', value: 'Building Lighting' },
                { label: 'Poles/ Luminaries', value: 'Poles/ Luminaries' },
                { label: 'Conduit bending/ Nipples/ Racks', value: 'Conduit bending/ Nipples/ Racks' },
                { label: 'JBox Prep', value: 'JBox Prep' },
                { label: 'Device/ Equipment Prep', value: 'Device/ Equipment Prep' },
                { label: 'Material Kitting', value: 'Material Kitting' },
                { label: 'Concrete Precast', value: 'Concrete Precast' },
                { label: 'CNC', value: 'CNC' },
                { label: 'Equipment Racks', value: 'Equipment Racks' },
                { label: 'Carpenter', value: 'Carpenter' },
                { label: 'R&D', value: 'R&D' },
                { label: 'Rework', value: 'Rework' },
                { label: 'Other', value: 'Other' }
              ]}
              disabled={!hasEditAccess || disabledForm}
            />
          </Grid>
          <Grid item xs={12} className='aldg-field'>
            <Grid container>
              <Grid item xs={12}>
                <InputAutocomplete
                  firebaseConfig={config}
                  firestoreOptions={{
                    method: 'onSnapshot',
                    collection: "Assembly",
                    where: [['data.Status', '==', 'Active'], ['data.TypeOfWork', '==', request.data.TypeOfWork]],
                    valueKey: "id"
                  }}
                  onChange={handleChange}
                  name="Assembly"
                  optionDisplay={(opts) => opts?.data?.Assembly}
                  label="Assembly"
                  value={request.data.Assembly || []}
                  disabled={!(user.admin || user.lead)}
                />
              </Grid>
              {
                request.data.SubAssemblies?.length > 0 ?
                  <Grid item xs={12}>
                    <AldgDataGrid
                      canAdd={false}
                      canDelete={false}
                      editable={(user.admin || user.lead)}
                      rows={request.data.SubAssemblies || []}
                      columns={columns}
                      updateOverride={updatePromises}
                      setUpdateOverride={setUpdatePromises}
                      ToolbarProps={{
                        canAdd: false,
                        canDelete: false,
                      }}
                      user={user}
                    />
                  </Grid> : null
              }
              {
                request.data.SubAssemblies?.length > 0 ?
                  <Grid item xs={12}>
                    <Typography variant='h6'>Total Percent Complete: {NaNtoNum(TotalPercentCompelete).toFixed(1)}%</Typography>
                  </Grid> : null
              }
            </Grid>
          </Grid>
          <Grid item className='aldg-field' xs={12}>
            <InputText
              name="BriefDescription"
              label="Brief Description of Scope of Work"
              onChange={handleChange}
              value={request.data.BriefDescription || ''}
              placeholder='Give us a brief description of what the scope is (50 characters or less)'
              disabled={!hasEditAccess || disabledForm}
            // maxLength={50}
            />
          </Grid>
          <Grid item className='aldg-field' xs={12}>
            <InputTextarea
              onChange={handleChange}
              name="ScopeOfWork"
              label="Scope of Work"
              value={request.data.ScopeOfWork || ''}
              disabled={!hasEditAccess || disabledForm}
            />
          </Grid>
          <Grid item className='aldg-field' xs={6}>
            <InputRadio
              radios={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' }
              ]}
              FormControlLabelProps={{
                label: 'Is there already an line for this in JPAC?'
              }}
              RadioGroupProps={{
                name: 'JPACLineItem',
                onChange: handleChange,
                value: request.data.JPACLineItem || '',
                disabled: !hasEditAccess || disabledForm
              }}
            />
          </Grid>
          <Grid item className='aldg-field' xs={6}>
            {request.data.JPACLineItem === 'No' ?
              <InputRadio
                radios={[
                  { value: 'Yes', label: 'Yes' },
                  { value: 'No', label: 'No' }
                ]}
                FormControlLabelProps={{
                  label: 'Does this work need a new activity line in JPAC?'
                }}
                RadioGroupProps={{
                  name: 'NewJPACLineNeeded',
                  onChange: handleChange,
                  value: request.data.NewJPACLineNeeded || '',
                  disabled: !hasEditAccess || disabledForm
                }}
              /> : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};


OrderInformation.propTypes = {
  request: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  disabledForm: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
};
OrderInformation.defaultProps = {};


export default OrderInformation;