import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import { InputDate, InputRadio, InputText, InputTextarea } from '@aldridge/aldg-data-components';

const OrderInformation = (props) => {
  const { request, handleChange, hasEditAccess, disabledForm, user } = props;
  return (
    <Card style={{ margin: '12px 0px' }}><CardHeader title='Material/Delivery Information' /><CardContent>

      <Grid container>
        <Grid item className='aldg-field' xs={12} md={12}>
          <InputText
            onChange={handleChange}
            name="TotalQuantity"
            value={request.data.TotalQuantity || ''}
            label="Total Quantity"
            disabled={!hasEditAccess || disabledForm}
          />
        </Grid>
        <Grid item className='aldg-field' xs={12} md={6}>
          <InputText
            onChange={handleChange}
            name="HoursBudgetedForPreFab"
            label="Hours Budgeted for PreFab"
            value={request.data.HoursBudgetedForPreFab || ''}
            disabled={!hasEditAccess || disabledForm}
          />
        </Grid>
        <Grid item className='aldg-field' xs={12} md={6}>
          <InputText
            onChange={handleChange}
            name="PreFabEstimatedHours"
            label="PreFab Estimated Hours"
            value={request.data.PreFabEstimatedHours || ''}
            disabled={!user.admin && !user.lead && !user.prefab}
          />
        </Grid>
        <Grid item className='aldg-field' xs={12}>
          <InputTextarea
            onChange={handleChange}
            name="DeliverySchedule"
            label="If Multiple Deliveries are Required, Provide a Detailed Schedule"
            value={request.data.DeliverySchedule || ''}
            disabled={!hasEditAccess || disabledForm}
          />
        </Grid>
        <Grid item className='aldg-field' xs={12} md={6}>
          <InputRadio
            radios={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
            FormControlLabelProps={{
              label: 'Made In America Requirement'
            }}
            RadioGroupProps={{
              name: 'MadeInAmericaRequirement',
              onChange: handleChange,
              value: request.data.MadeInAmericaRequirement || '',
              disabled: !hasEditAccess || disabledForm
            }}
          />
        </Grid>
        <Grid item className='aldg-field' xs={12} md={6}>
          <InputRadio
            radios={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
            FormControlLabelProps={{
              label: 'Is Bill of Material Complete and Attached?'
            }}
            RadioGroupProps={{
              name: 'BillOfMaterialComplete',
              onChange: handleChange,
              value: request.data.BillOfMaterialComplete || '',
              disabled: !hasEditAccess || disabledForm
            }}
          />
        </Grid>
        <Grid item className='aldg-field' xs={12} md={6}>
          <InputRadio
            radios={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
            FormControlLabelProps={{
              label: 'Material Has Been Ordered'
            }}
            RadioGroupProps={{
              name: 'MaterialOrdered',
              onChange: handleChange,
              value: request.data.MaterialOrdered || '',
              disabled: !hasEditAccess || disabledForm
            }}
          />
        </Grid>
        <Grid item className='aldg-field' xs={12}>
          <InputRadio
            radios={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' }
            ]}
            FormControlLabelProps={{
              label: 'Has the Design & BOM Been Reviewed and Approved by PreFab & Project Team?'
            }}
            RadioGroupProps={{
              name: 'ApprovedByPreFabProjectTeam',
              onChange: handleChange,
              value: request.data.ApprovedByPreFabProjectTeam || '',
              disabled: !hasEditAccess || disabledForm
            }}
          />
          {request.data.ApprovedByPreFabProjectTeam !== 'No' ? null :
            <Typography variant="p" >Contact the PreFab (prefab@aldridge-electric.com) or Project team for Assistance</Typography>}
        </Grid>
        {request.data.MaterialOrdered !== 'Yes' ? null :
          <Grid item className='aldg-field' xs={12} md={6}>
            <InputText
              onChange={handleChange}
              name="PoNumber"
              label="PO Number"
              value={request.data.PoNumber || ''}
              disabled={!hasEditAccess || disabledForm}

            />
          </Grid>}
        {request.data.MaterialOrdered !== 'Yes' ? null :
          <Grid item className='aldg-field' xs={12} md={6} >
            <InputDate
              label="Estimated Ship-Date Incoming"
              onChange={handleChange}
              name="EstimatedShipDate"
              value={request.data.EstimatedShipDate || ''}
              disabled={!hasEditAccess || disabledForm}
            />
          </Grid>}
        {request.data.MaterialOrdered !== 'Yes' ? null :
          <Grid item className='aldg-field' xs={12} md={6}>
            <InputText
              onChange={handleChange}
              name="YardLinkLocation"
              label="Link for Yard Location"
              value={request.data.YardLinkLocation || ''}
              disabled={!hasEditAccess || disabledForm}
            />
          </Grid>}
        {request.data.MaterialOrdered !== 'Yes' ? null :
          <Grid item className='aldg-field' xs={12} md={6}>
            <InputText
              onChange={handleChange}
              name="BuildComplete"
              label="Link for Build Complete"
              value={request.data.BuildComplete || ''}
              disabled={!hasEditAccess || disabledForm}
            />
          </Grid>}
      </Grid>
    </CardContent></Card>
  );
};


OrderInformation.propTypes = {
  request: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  disabledForm: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired
};
OrderInformation.defaultProps = {};


export default OrderInformation;