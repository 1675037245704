import {
  faHome, faLocationDot, faUserGroup, faArrowProgress
} from '@fortawesome/pro-regular-svg-icons';

const SidebarData = (user) =>
  [
    {
      href: '/requests', // link to location
      icon: faHome, // icon, from font awesome
      title: 'Requests', // label
      dock: true, // true if want to appear on the bottom navigation bar on mobile
    },
    {
      href: '/locations',
      icon: faLocationDot,
      title: 'Locations',
      dock: false,
      hide: !user.admin
    },
    {
      href: '/team',
      icon: faUserGroup,
      title: 'Team',
      dock: false,
      hide: !user.admin
    },
    {
      href: '/assembly',
      icon: faArrowProgress,
      title: 'Sub Assemblies',
      dock: false,
      hide: !user.admin
    }
  ];
export default SidebarData;