import React, { useState, useEffect, useContext } from 'react';
import { Modal, Backdrop, Grid } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { onSnapshot, orderBy, query, collection } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { _AppVersion, _ShowChangelogModal } from '../../_Recoil/atoms';
import { UserContext } from '../../providers/UserProvider';
import ErrorBoundary from '../ErrorBoundary';

const ChangelogModal = () => {
  const [logs, setLogs] = useState([]);
  const user = useContext(UserContext);
  const [ShowChangelogModal, setShowChangelogModal] = useRecoilState(_ShowChangelogModal);
  const VersionNumber = useRecoilValue(_AppVersion);
  useEffect(() => {
    let mounted = true;
    if (mounted) setShowChangelogModal(localStorage.getItem('ChangelogShown') === null && typeof user !== 'undefined' && user !== null);
    return () => (mounted = false);
  }, [user]);

  useEffect(() => {
    let mounted = true;
    if (ShowChangelogModal) {
      const q = query(collection(firestore, 'Changelog'), orderBy('Version', 'desc'), orderBy('DeployDate', 'desc'));
      onSnapshot(q, (docs) => {
        const allLogs = [];
        docs.forEach((d) => allLogs.push(d.data()));
        // sort by version number by splitting version on the period and sorting by each number
        allLogs.sort((a, b) => {
          const aSplit = a.Version.split('.');
          const bSplit = b.Version.split('.');
          for (let i = 0; i < aSplit.length; i++) {
            if (parseInt(aSplit[i], 10) > parseInt(bSplit[i], 10)) return -1;
            if (parseInt(aSplit[i], 10) < parseInt(bSplit[i], 10)) return 1;
          }
          return 0;
        });
        console.log(allLogs);
        if (mounted) setLogs(allLogs);
      });
    }
    return () => (mounted = false);
  }, [ShowChangelogModal]);

  const close = () => {
    localStorage.setItem('ChangelogShown', 'true');
    setShowChangelogModal(false);
  };

  return (
    <ErrorBoundary componentName='Changelog Modal' user={user}>
      <Grid container>
        <Modal open={ShowChangelogModal} onClose={close} BackdropComponent={Backdrop} disablePortal>
          <div className='aldg-modalPaper' style={{ paddingTop: '0px' }}>
            <div className='aldg-modalHeader'>
              <h1>What&apos;s New!</h1>
              <button className='aldg-closeBtn' type='button' onClick={close}>
                <b>X</b>
              </button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '80%' }}>You are currently on version: <strong>{VersionNumber}</strong>. If this version doesn&apos;t match the latest deployed version, make sure to close any additional browsers and do a <strong>&quot;Force Application Refresh&quot;</strong> from the side menu under the three bars.</div>

            </div>
            <div className='aldg-modalBody'>
              {logs
                .filter((c) => (c.Deployed || 'Yes') === 'Yes')
                .map((log) => (
                  <div key={log.id}>
                    <div style={{ border: '1px ridge gray', marginTop: '6px' }} />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px 0px'
                      }}
                    >
                      <div>{log.Version}</div>
                      <i>{log.DeployDate}</i>
                    </div>
                    <ul>
                      {log.Changes.map((change, idx) => (
                        <li
                          style={{
                            textAlign: 'left',
                            marginLeft: '28px',
                            padding: '4px 0px'
                          }}
                          key={`${change.id}_${idx.toString()}`}
                        >
                          {change.Change}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </Modal>
      </Grid>
    </ErrorBoundary>
  );
};

export default ChangelogModal;
