
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { useHistory } from 'react-router-dom';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { firestore } from '../firebase';

const AssemblyList = () => {
  const history = useHistory();
  const [Assemblies, setAssemblies] = useState([]);
  const [AssembliesLoading, setAssembliesLoading] = useState(false);
  const [selection, setSelection] = useState([]);

  const handleSelect = (idx) => {
    history.push(`/Assembly/${idx}`);
  };

  const apiRef = useGridApiRef();


  useEffect(() => {
    let mounted = true;
    try {
      if (mounted)
        setAssembliesLoading(true);
      onSnapshot(collection(firestore, 'Assembly'), (querySnapshot) => {
        if (mounted)
          setAssembliesLoading(false);
        const allRequests = [];
        querySnapshot.forEach((d) => {
          allRequests.push(d.data());
        });
        if (mounted)
          setAssemblies(allRequests);
      }, (err) => {
        toast.error(err.message);
      });
    } catch (err) {
      toast.error(err.message);
    }
    return () => { mounted = false; };
  }, []);

  const columns = [
    { field: "TypeOfWork", headerName: "Type of Work", valueGetter: (params) => params.row.data.TypeOfWork, flex: 20 },
    { field: "Assembly", valueGetter: (params) => params.row.data.Assembly, flex: 20 },
    { field: "NumberOfSubs", headerName: 'Number of Sub Assemblies', valueGetter: (params) => params.row.data.SubAssemblies.length, flex: 20 },
    { field: "Status", valueGetter: (params) => params.row.data.Status, flex: 20 },
  ];

  const deleteSelected = () => {
    const selectedRecords = [...Array.from(apiRef.current.getSelectedRows())];
    if (selectedRecords.length > 0) {
      selectedRecords.forEach((r) => {
        deleteDoc(doc(firestore, 'Assembly', r[0]));
      });
    }

  };
  return (
    <div style={{ padding: '0 10px', overflowX: 'auto', backgroundColor: 'white' }}>
      <Grid container>
        <div style={{ paddingTop: '10px' }} />
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '20px 0',
            justifyContent: 'flex-end',
            position: 'sticky',
            top: 0,
            zIndex: 2000,
            backgroundColor: 'white'
          }}
        >
          <ToolTipIconButton onClick={() => handleSelect('new')} tooltipText="New Record" color='primary' icon={faPlus} />
          <ToolTipIconButton onClick={deleteSelected} tooltipText="Delete Records" color='error' icon={faTrash} />
        </div>
        {/* <Grid item xs={11} />
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ToolTipIconButton icon={faPlus} tooltipText="New Record" onClick={() => handleSelect('new')} />
        </Grid> */}
        <Grid item xs={12}>
          <AldgDataGrid
            autoHeight
            canAdd={false}
            canDelete
            checkboxSelection
            getRowId={(r) => r.id}
            loading={AssembliesLoading}
            onSelectionModelChange={(newSelection) => setSelection(newSelection)}
            onRowClick={(row) => handleSelect(row.id)}
            selectionModel={selection}
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={50}
            apiRef={apiRef}
            rows={Assemblies}
            columns={columns}
          />
        </Grid>
      </Grid>
    </div>
  );
};


export default AssemblyList;