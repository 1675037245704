import React, { useEffect, useState } from 'react';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { Grid, Paper } from '@mui/material';

import { collection, onSnapshot } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import { firebaseConfig, firestore } from '../firebase';

const TypeOfWorkList = () => {
  const [work, setWork] = useState([]);
  const history = useHistory();

  useEffect(() => {
    onSnapshot(collection(firestore, 'TypeOfWork'), (snap) => {
      const all = [];
      snap.forEach(d => all.push(d.data()));
      setWork(all);
    });
  }, []);

  const columns = [
    {
      field: 'TypeOfWork',
      headerName: 'Type of Work',
      canEdit: true,
      inputType: 'InputText',
      flex: 100
    }
  ];

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <AldgDataGrid
            editable
            columns={columns}
            firebaseConfig={firebaseConfig}
            onRowClick={(r) => history.push(`/TypeOfWork/${r.id}`)}
            collection="TypeOfWork"
            rows={work}
          />
        </Grid>
      </Grid>
    </Paper>
  );

};

export default TypeOfWorkList;