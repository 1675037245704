import React, { useContext, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import { auth } from '../firebase';
import useInitializeOffline from '../_GlobalComponents/InitializeOffline';
import {
  _InitedOffline
} from '../_Recoil/atoms';
import { UserContext } from './UserProvider';

export const OfflineDataContext = createContext({ user: null });
const OfflineDataProvider = (props) => {
  const user = useContext(UserContext);
  const { syncs, addSync, syncData, setInitialCount } = useInitializeOffline();
  const { children } = props;
  const [InitedOffline, setInitedOffline] = useRecoilState(_InitedOffline);

  useEffect(() => {
    let mounted = true;
    if (auth.currentUser) {
      if (!InitedOffline.value) {
        if (
          user
        ) {

          // CUSTOMIZED PER APP
          setInitialCount(0);
          // addSync(
          //   'ENT-Employees',
          //   {
          //     collection: 'ENT-Employees',
          //     where: [['Status', '==', 'A']]
          //   },
          //   `ECMS Employees`
          // );
          if (mounted)
            setInitedOffline({ value: true, lastInited: new Date().toJSON() });
        } else {
          // toast.info('Enter a Job and a Date to intialize offline data.', {
          //   toastId: 'FailedToDownload'
          // });
        }
      }
    }
    return () => (mounted = false);
  }, [user, InitedOffline]);

  return (
    <OfflineDataContext.Provider
      // eslint-disable-next-line
      value={{ syncs, addSync, syncData, setInitialCount }}
    >
      {children}
    </OfflineDataContext.Provider>
  );
};

OfflineDataProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any)
};
OfflineDataProvider.defaultProps = {
  children: []
};
export default OfflineDataProvider;
