import React from 'react';
import PropTypes from 'prop-types';
import { PDFExport } from '@progress/kendo-react-pdf';
import RequestPDF from '../../RequestPDF';



const PdfView = (props) => {
  const { showPrev, request, previewPDFref } = props;

  if (!showPrev) return null;
  return (
    <>
      <h4 style={{ width: '100%' }}>{['Draft', '1. Requested', 'Rejected'].indexOf(request.data.Status) > -1 ? 'Request ' : 'Order '} Preview</h4>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <PDFExport
          ref={previewPDFref}
          paperSize="A4"
          scale={0.66}
          margin="1cm"
          keepTogether="div"
          fileName={`Order ${typeof request.data.OrderNumber === 'undefined' || request.data.OrderNumber === '' ? '' : request.data.OrderNumber} Overview PDF`}
        >
          <RequestPDF request={request} />
        </PDFExport>
      </div>
    </>
  );
};


PdfView.propTypes = {
  request: PropTypes.objectOf(PropTypes.any).isRequired,
  previewPDFref: PropTypes.objectOf(PropTypes.any).isRequired,
  showPrev: PropTypes.bool.isRequired
};
PdfView.defaultProps = {};


export default PdfView;