import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';
import SignIn from './SignIn';
import Unauthorized from './Unauthorized';
import NoMatch from './NoMatch';
import RequestList from './RequestList';
import RequestForm from './RequestForm';
import TimeUpload from './TimeUpload';
import PreFabLocationList from './PreFabLocationList';
import PreFabLocation from './PreFabLocation';
import PreFabTeamList from './PreFabTeam';
import TypeOfWorkList from './TypeOfWorkList';
import AssemblyList from './AssemblyList';
import Assembly from './Assembly';
import Changelog from '../_GlobalComponents/Changelog/Changelog';
import ChangelogList from '../_GlobalComponents/Changelog/ChangelogList';
import Test from './Test';

const Application = () => {
  const user = useContext(UserContext);
  const page = () => {
    if (!user) {
      return <SignIn />;
    }
    if (user && (user.admin || user.user)) {
      return (
        <Switch>
          <Route exact path="/" component={RequestList} />
          {user.admin && <Route exact path="/team" component={PreFabTeamList} />}
          <Route exact path="/requests" component={RequestList} />
          <Route exact path="/requests/:id" component={RequestForm} />
          <Route exact path="/locations" component={user.admin ? PreFabLocationList : NoMatch} />
          <Route exact path="/locations/:id" component={user.admin ? PreFabLocation : NoMatch} />
          <Route exact path="/timeUpload" component={user.admin ? TimeUpload : NoMatch} />
          <Route exact path="/TypeOfWork" component={user.admin ? TypeOfWorkList : NoMatch} />
          <Route exact path="/TypeOfWork/:id" component={user.admin ? TypeOfWorkList : NoMatch} />
          <Route exact path="/Assembly" component={user.admin ? AssemblyList : NoMatch} />
          <Route exact path="/Assembly/:id" component={user.admin ? Assembly : NoMatch} />
          <Route exact path="/Assembly" component={user.admin ? AssemblyList : NoMatch} />
          <Route exact path="/Changelog" component={user.SuperAdmin ? ChangelogList : NoMatch} />
          <Route exact path="/Changelog/:id" component={user.SuperAdmin ? Changelog : NoMatch} />
          <Route exact path="/Test" component={user.SuperAdmin ? Test : NoMatch} />
          {/* <Route exact path="/orders" component={OrderList} /> */}
          {/* <Route exact path="/orders/:id" component={OrderForm} /> */}
          <Route exact><NoMatch /></Route>
        </Switch>
      );
    }

    return <Unauthorized />;
  };

  return (
    <div className="appBody">
      {
        page()
      }
    </div>
  );
};
export default Application;
