import * as React from 'react';
import {
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';

const QuickSearchToolbar = () => (
  <div style={{
    padding: '4px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  }}>
    <div>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </div>
  </div>);

QuickSearchToolbar.propTypes = {

};

export default QuickSearchToolbar;