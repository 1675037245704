import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../../../_GlobalComponents/Breadcrumbs';


const RequestBreadcrumb = (props) => {
  const { request } = props;

  return (
    <Breadcrumb path={[{ name: 'Requests', active: true, link: '/requests' }, { name: `${['1. Requested', 'Draft', 'Rejected'].indexOf(request.data.Status) > -1 ? 'Request for' : 'Order Number'} ${['1. Requested', 'Draft', 'Rejected'].indexOf(request.data.Status) > -1 ? request.data.JobNumberDisplay : request.data.OrderNumber}`, active: false }]} />
  );
};

RequestBreadcrumb.propTypes = {
  request: PropTypes.objectOf(PropTypes.any).isRequired
};


export default RequestBreadcrumb;
