/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Backdrop, Modal, Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCloud, faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import {
  collection,
  getDocsFromCache,
  onSnapshot,
  waitForPendingWrites
} from 'firebase/firestore';
import { _InitingOffline } from '../_Recoil/atoms';
import { firestore } from '../firebase';

const IndexedDbWrapper = ({ projectId, children }) => {
  const InitingOffline = useRecoilValue(_InitingOffline);
  const [pendingRecords, setPendingRecords] = useState({});
  const [pendingRecordsCount, setPendingRecordsCount] = useState(0);
  const [displayBoxOpen, setDisplayBoxOpen] = useState(false);
  const [IndexedDbExists, setIndexedDbExists] = useState(false);
  const theme = createTheme();

  useEffect(() => {
    const setI = setInterval(() => {
      try {
        // CUSTOMIZED PER APP
        Promise.all([
          // getDocsFromCache(collection(firestore, 'Crew')),
          // getDocsFromCache(collection(firestore, 'DailyReport')),
          // getDocsFromCache(collection(firestore, 'Equipment')),
          // getDocsFromCache(collection(firestore, 'Subcontractors')),
          // getDocsFromCache(collection(firestore, 'Tasks')),
          // getDocsFromCache(collection(firestore, 'TimeEntries')),
          // getDocsFromCache(collection(firestore, 'PICC'))
        ]).then((docs) => {
          const pendingWrites = {
            // Crew: {
            //   hasPendingRecords: docs[0].metadata.hasPendingWrites,
            //   pendingRecords: docs[0].docs.filter(
            //     (d) => d.metadata.hasPendingWrites
            //   ).length
            // },
            // DailyReport: {
            //   hasPendingRecords: docs[1].metadata.hasPendingWrites,
            //   pendingRecords: docs[1].docs.filter(
            //     (d) => d.metadata.hasPendingWrites
            //   ).length
            // },
            // Equipment: {
            //   hasPendingRecords: docs[2].metadata.hasPendingWrites,
            //   pendingRecords: docs[2].docs.filter(
            //     (d) => d.metadata.hasPendingWrites
            //   ).length
            // },
            // Subcontractors: {
            //   hasPendingRecords: docs[3].metadata.hasPendingWrites,
            //   pendingRecords: docs[3].docs.filter(
            //     (d) => d.metadata.hasPendingWrites
            //   ).length
            // },
            // Tasks: {
            //   hasPendingRecords: docs[4].metadata.hasPendingWrites,
            //   pendingRecords: docs[4].docs.filter(
            //     (d) => d.metadata.hasPendingWrites
            //   ).length
            // },
            // TimeEntries: {
            //   hasPendingRecords: docs[5].metadata.hasPendingWrites,
            //   pendingRecords: docs[5].docs.filter(
            //     (d) => d.metadata.hasPendingWrites
            //   ).length
            // },
            // PICC: {
            //   hasPendingRecords: docs[6].metadata.hasPendingWrites,
            //   pendingRecords: docs[6].docs.filter(
            //     (d) => d.metadata.hasPendingWrites
            //   ).length
            // };
          };
          const knt = Object.values(pendingWrites)
            .map((r) => r.pendingRecords)
            .reduce((partialSum, a) => partialSum + a, 0);

          setPendingRecords(pendingWrites);
          setPendingRecordsCount(knt);
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }, 5000);

    return () => {
      clearInterval(setI);
    };
  }, []);

  const displayChangeBox = () => {
    setDisplayBoxOpen(!displayBoxOpen);
  };

  const pulseBackground = {
    animatedItem: {
      position: 'absolute',
      top: '-5px',
      left: '-5px',
      transform: 'translate(-5px, -5px)',
      borderRadius: '50%',
      width: '40px !important',
      height: '40px !important',
      border: '5px solid #e5e5e5',
      animation: '$pulse 1s ease-out infinite'
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0)',
        opacity: '0.0'
      },
      '25%': {
        transform: 'scale(0.1)',
        opacity: '0.2'
      },
      '50%': {
        transform: 'scale(0.5)',
        opacity: '0.5'
      },
      '75%': {
        transform: 'scale(0.8)',
        opacity: '0.8'
      },
      '100%': {
        transform: 'scale(1)',
        opacity: '0.0'
      }
    }
  };

  return (
    <>
      <Modal
        open={displayBoxOpen}
        onClose={displayChangeBox}
        BackdropComponent={Backdrop}
      >
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '4px'
            }}
          >
            <h4 style={{ textDecoration: 'underline' }}>Pending Records</h4>
          </div>
          <div>
            {pendingRecordsCount > 0 ? (
              <>
                <div
                  style={{
                    position: 'relative',
                    flex: '1 1 auto',
                    padding: '1rem 0 0 0',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    style={{
                      padding: '0 5px',
                      color: '#979797',
                      fontWeight: 'bold'
                    }}
                  >
                    Forms with Pending Records
                  </div>
                  <div
                    style={{
                      padding: '0 5px',
                      color: '#979797',
                      fontWeight: 'bold'
                    }}
                  >
                    # of Pending Records
                  </div>
                </div>
                {Object.keys(pendingRecords).map((prop) =>
                  pendingRecords[prop].hasPendingRecords ? (
                    <div
                      style={{
                        position: 'relative',
                        flex: '1 1 auto',
                        padding: '.75rem 0',
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                      key={prop}
                    >
                      <div style={{ padding: '0 5px' }}>{prop}</div>
                      <div style={{ padding: '0 5px' }}>
                        {pendingRecords[prop].pendingRecords}
                      </div>
                    </div>
                  ) : null
                )}
              </>
            ) : (
              <div style={{ paddingTop: '6px' }}>No Pending Records.</div>
            )}
          </div>
        </div>
      </Modal>
      <div
        style={{
          position: 'relative',
          margin: '0 1px',
          display: 'flex',
          padding: '5px',
          backgroundColor: 'white',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          alignItems: 'center'
        }}
        title='Pending Records'
      >
        <Box
          sx={
            pendingRecordsCount > 0 ? pulseBackground : {}
          }
        />
        {pendingRecordsCount === 0 ? (
          <span
            className='fa-layers fa-fw'
            style={{ cursor: 'pointer' }}
            onClick={displayChangeBox}
            role='button'
          >
            <FontAwesomeIcon style={{ color: '#00ca00' }} icon={faCloud} />
            <FontAwesomeIcon
              style={{ color: 'white', fontSize: '.7rem', paddingTop: '2px' }}
              icon={faCheck}
            />
          </span>
        ) : (
          <span
            className='fa-layers fa-fw'
            style={{ cursor: 'pointer' }}
            onClick={displayChangeBox}
            role='button'
          >
            <FontAwesomeIcon style={{ color: '#ca0000' }} icon={faCloud} />
            <FontAwesomeIcon
              style={{ color: 'white', fontSize: '.7rem', paddingTop: '2px' }}
              icon={faArrowUp}
            />
          </span>
        )}
      </div>
      {children.length > 0 ? (
        <span
          onClick={displayChangeBox}
          style={{ cursor: 'pointer' }}
          role='button'
        >
          {children}
        </span>
      ) : null}
    </>
  );
};

export default IndexedDbWrapper;

IndexedDbWrapper.propTypes = {
  projectId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.string
  ])
};
IndexedDbWrapper.defaultProps = {
  children: []
};
