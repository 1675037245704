/* eslint-disable no-restricted-globals */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect, useState,
} from 'react';
import { Grid, useMediaQuery, useTheme, Stack, Typography, Switch } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { existsWithLength, percentFormat } from '@aldridge/aldg-helpers';
import { firebaseConfig, firestore } from '../firebase';
import QuickSearchToolbar from '../_helpers/QuickSearchToolbar';
import {
  _CurrentFilter
} from '../_Recoil/atoms';
import { UserContext } from '../providers/UserProvider';

const RequestList = (props) => {
  const { history } = props;
  const [requests, setRequests] = useState([]);
  const [requestsLoading, setRequestsLoading] = useState(false);
  const [selection, setSelection] = useState([]);
  const [checked, setChecked] = useState(true);
  const [CurrentFilter, setCurrentFilter] = useRecoilState(_CurrentFilter);

  const user = useContext(UserContext);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);

  useEffect(() => {
    let mounted = true;
    try {
      if (mounted)
        setRequestsLoading(true);
      let q = query(collection(firestore, 'RequestsOrders'));
      if (user.email === 'tdoe@aldridge-electric.com') {
        q = query(q, orderBy('data.OrderNumber', 'desc'));
      } else {
        q = query(q, orderBy('data.Status'));
      }
      if (!user.admin) {
        if (user.lead && checked) {
          q = query(q, where('data.PreFabLead', 'array-contains', user.email));
        } else if (user.prefab && checked) {
          q = query(q, where('data.PreFabTeam', 'array-contains', user.email));
        } else if (user.user && checked) {
          q = query(q, where('SharedWith', 'array-contains', user.email));
        }
      }
      onSnapshot(q, (querySnapshot) => {
        if (mounted)
          setRequestsLoading(false);
        const allRequests = [];
        querySnapshot.forEach((d) => {
          allRequests.push(d.data());
        });
        if (mounted)
          setRequests(allRequests);
      }, (err) => {
        toast.error(err.message);
        console.log(err);
      });
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    return () => { mounted = false; };
  }, [checked]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('lg'));

  const getFlex = (f) => (isMobile ? null : f);
  const getWidth = (w) => (isMobile ? w : null);

  // write a function that will strip out all characters between the square brackets 
  // and then return the string without the characters between the square brackets
  const stripBrackets = (str) => str.replace(/\[.*?\]/g, '').split(',').map(r => r.trim()).join(', ');

  console.log(theme, tablet);
  const columns = [
    {
      field: 'Status',
      headerName: 'Status',
      valueGetter: (params) => params.row.data.Status,
      flex: getFlex(15),
      width: getWidth(125),
      sortable: true,
      type: 'singleSelect',
      valueOptions: [
        '1. Requested'
        , '2. Awaiting Material'
        , '3. Awaiting Scheduling'
        , '4. In Progress'
        , '5. On Hold'
        , '6. Complete'
        , 'Awaiting Shipment'
        , 'Draft'
        , 'Rejected'
      ],
    },
    {
      field: 'OrderNumber',
      headerName: 'Order Number',
      valueGetter: (params) => params.row.data.OrderNumber,
      flex: getFlex(9),
      width: getWidth(125),
      sortable: true,
      type: 'string'
    },
    !isMobile ? {
      field: 'Division',
      headerName: 'Division',
      valueGetter: (params) => {
        if (params.row.data.JobNumberDisplay.indexOf('[Division: ') > -1) {
          return params.row.data.JobNumberDisplay.split('[Division: ')[1].split(']')[0];
        }
        return params.row.data.JobNumberDisplay.split(' - ')[0]?.split('.')[1]?.trim();
      },
      flex: getFlex(10),
      width: getWidth(100),
      sortable: true
    } : null,
    {
      field: 'JobNumber',
      headerName: 'Job Number',
      valueGetter: (params) => {
        if (isMobile) {
          return params.row.data.JobNumberDisplay?.split(' - ')?.[0]?.trim();
        }
        return params.row.data.JobNumberDisplay;
      },
      flex: getFlex(15),
      width: getWidth(150),
      sortable: true
    },
    {
      field: 'PreFabLead',
      headerName: 'PreFab Lead',
      valueGetter: (params) => stripBrackets(params.row.data.PreFabLeadDisplay || ''),
      flex: getFlex(15),
      width: getWidth(125),
      sortable: true
    },
    {
      field: 'BriefDescription',
      headerName: 'Ticket Title',
      valueGetter: (params) => params.row.data.BriefDescription,
      flex: getFlex(15),
      width: getWidth(300),
      sortable: false
    },
    !isMobile ? {
      field: 'CreatedDate',
      headerName: 'Submitted On',
      type: 'date',
      valueGetter: (params) => params.row?.CreatedDate,
      valueFormatter: (params) => {
        if (!isMobile) return params.value !== '' ? dayjs(params.value).tz('America/Chicago').format('ll') : '';
        return params.value !== '' ? dayjs(params.value).tz('America/Chicago').format('MM/DD/YYYY') : '';
      },
      flex: getFlex(10),
      width: getWidth(100),
      sortable: true
    } : null,
    !isMobile ? {
      field: 'CreatedBy',
      headerName: 'Requestor',
      valueGetter: (params) => params.row?.CreatedBy,
      flex: getFlex(10),
      width: getWidth(150),
      sortable: true
    } : null,
    {
      field: 'DateNeededBy',
      headerName: 'PreFab Completed By',
      type: 'date',
      valueGetter: (params) => params.row.data?.DateNeededBy,
      valueFormatter: (params) => {
        if (!isMobile) return params.value !== '' ? dayjs(params.value).tz('America/Chicago').format('ll') : '';
        return params.value !== '' ? dayjs(params.value).tz('America/Chicago').format('MM/DD/YYYY') : '';
      },
      flex: getFlex(10),
      width: getWidth(125),
      sortable: true
    }, {
      field: 'data.PercentComplete',
      headerName: '% Complete',
      type: 'number',
      valueGetter: (params) => params.row.data.PercentComplete,
      valueFormatter: (params) => percentFormat(params.value),
      cellClassName: () => user.admin || user.lead ? 'editable' : '',
      canEdit: true,
      inputType: 'InputNumber',
      step: 5,
      flex: getFlex(8),
      width: getWidth(125),
      sortable: false
    },
    !isMobile ? {
      field: 'data.PercentCompleteUpdated',
      headerName: '% Complete Updated',
      type: 'date',
      valueGetter: (params) => params.row.data.PercentCompleteUpdated ? params.row.data.PercentCompleteUpdated : '',
      valueFormatter: (params) => params.value ? dayjs(params.value).tz('America/Chicago').format('lll') : '',
      flex: getFlex(8),
      width: getWidth(50),
      sortable: false
    } : null
  ];
  if ((user.admin || user.lead || user.prefab)) {
    if (!isMobile)
      columns.unshift({
        field: 'New Comments',
        type: 'boolean',
        headerName: '',
        width: 8,
        flex: 2,
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row.data.Notifications?.[user.email],
        renderCell: (params) => params.row.data.Notifications?.[user.email] ? <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }} title="Unread Comment" beatFade /> : ''
      });
    if (!isMobile)
      columns.push(
        {
          field: 'TypeOfWork',
          headerName: 'Type of Work',
          valueGetter: (params) => params.row.data.TypeOfWork,
          flex: getFlex(8),
          width: getWidth(50),
          sortable: false,
          type: 'singleSelect',
          valueOptions: [
            "Welding/ Fabrication/ Jigs",
            "Underground/ Ductbank",
            "Cable/ Wire Prep",
            "Building Lighting",
            "Poles/ Luminaries",
            "Conduit bending/ Nipples/ Racks",
            "JBox Prep",
            "Device/ Equipment Prep",
            "Material Kitting",
            "Concrete Precast",
            "CNC",
            "Equipment Racks",
            "Carpenter",
            "R&D",
            "Rework",
            "Other"
          ],
        }
      );
  }

  const handleSelect = (idx) => {
    history.push(`/requests/${idx}`);
  };

  const apiRef = useGridApiRef();
  useEffect(() => {
    const handleFilterModelChange = (params) => {
      setCurrentFilter(params.items);
    };
    if (existsWithLength(apiRef.current)) {
      if (CurrentFilter.length > 0) {
        apiRef.current.setFilterModel({ items: CurrentFilter });
      }
      // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
      return apiRef.current.subscribeEvent(
        'filterModelChange',
        handleFilterModelChange
      );
    }
    return () => { };
  }, [apiRef]);
  return (
    <div style={{ padding: '0 10px', overflowX: 'auto', backgroundColor: 'white' }}>
      <Grid container>
        <div style={{ paddingTop: '10px' }} />
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: matches ? '20px 0' : '10px 0',
            justifyContent: 'flex-end',
            position: 'sticky',
            top: 0,
            zIndex: 2000,
            backgroundColor: 'white'
          }}
        >
          <ToolTipIconButton onClick={() => handleSelect('new')} tooltipText="New Record" color='primary' icon={faPlus} />
        </div>
        {/* <Grid item xs={11} />
        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ToolTipIconButton icon={faPlus} tooltipText="New Record" onClick={() => handleSelect('new')} />
        </Grid> */}
        {(!user.admin) && <Grid item xs={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>All Orders</Typography>
            <Switch checked={checked} onClick={(e) => setChecked(e.target.checked)} inputProps={{ 'aria-label': 'ant design' }} />
            <Typography>My Orders</Typography>
          </Stack>
        </Grid>}
        <Grid item xs={12}>
          <AldgDataGrid
            editable={user.admin || user.lead}
            autoHeight
            extraClasses={[
              {
                '& .editable': {
                  bgcolor: '#cddeed',
                  fontWeight: '600',
                  borderBottom: '1px solid #b3b9bf !important',
                  cursor: 'pointer'
                }
              }
            ]}
            FirebaseProps={
              {
                firebaseConfig,
                collection: 'RequestsOrders'
              }
            }
            canAdd={false}
            canDelete={user.admin}
            getRowId={(r) => r.id}
            loading={requestsLoading}
            onSelectionModelChange={(newSelection) => setSelection(newSelection)}
            onRowClick={(row) => handleSelect(row.id)}
            selectionModel={selection}
            components={{ Toolbar: QuickSearchToolbar }}
            pagination
            paginationModel={{
              pageSize: 100,
            }}
            pageSizeOptions={[100, 200, 300]}
            apiRef={apiRef}
            rows={requests}
            columns={columns.filter(a => a !== null)}
            gridState={{ gridAtom: CurrentFilter, setGridAtom: setCurrentFilter }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

RequestList.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number,
    action: PropTypes.string,
    location: PropTypes.objectOf(PropTypes.string, PropTypes.object),
    push: PropTypes.func,
    replace: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    block: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.object),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string
  }),
};
RequestList.defaultProps = {
  history: {
    push: () => { }
  },
  match: {
    url: ''
  }
};

export default RequestList;
