/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { existsWithLength, isUndefined, useFunctionCall } from '@aldridge/aldg-helpers';
import { list, ref } from 'firebase/storage';
import { setDoc, doc, collection } from 'firebase/firestore';
import { faArrowDown, faFilePdf, faSave, faTimes, faEye, faEyeSlash, faEnvelope, faShare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TailSpin } from 'react-loader-spinner';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faEnvelopeOpen } from '@fortawesome/pro-regular-svg-icons';
import { firestore, storage } from '../../../firebase';
import { SendOrderUpdateEmail, SendStatusEmail, errorCheck } from '../../../_helpers/errorCheck';

const StatusButtons = (props) => {
  const { user, request, setSaving, match, history, showPrev, setOrderNumber, handleApproval, handleRejection, viewPrev, downloadPrev, handleSendComments, handleNewUser, adminRoles, StatusUpdated, setStatusUpdated } = props;

  const { data: emailData, loading: emailLoading, error: emailError, functionCall: sendEmail } = useFunctionCall('SendEmail', {});
  useEffect(() => {
    if (!emailLoading && !isUndefined(emailData)) {
      toast.success(emailData);
    }
    if (!emailLoading && !isUndefined(emailError)) {
      toast.error(emailError);
    }
  }, [emailData, emailLoading, emailError]);

  const SaveOrderEmail = async (rec) => {
    // if (editableOverride || ((user.admin || user.prefab || user.lead) && editableOverride2)) {
    //   toast.warning('Please save or discard your comments before saving your record.', { autoClose: 10000 });
    //   return;
    // }
    rec.ModifiedBy = user.email;
    rec.ModifiedDate = new Date().toJSON();
    await setDoc(doc(firestore, 'RequestsOrders', rec.id), rec, { merge: true }).catch(err => toast.error(err));
    await setOrderNumber(rec);
    SendStatusEmail(sendEmail, rec, adminRoles.map(r => r.Lead[0]));
    setSaving(false);
  };

  const handleComplete = async () => {
    // if (editableOverride || ((user.admin || user.prefab || user.lead) && editableOverride2)) {
    //   toast.warning('Please save or discard your comments before saving your record.', { autoClose: 10000 });
    //   return;
    // }
    setSaving(true);
    const rec = { ...request };
    const completedPhtos = ref(storage, `${rec.id}/CompletedBuildAttachments`);
    const listRef = await list(completedPhtos);
    if ((typeof rec.data.CompletedBuildQuantity !== 'undefined' && rec.data.CompletedBuildQuantity !== '')
      && (typeof rec.data.CompletedBuildStorageLocation !== 'undefined' && rec.data.CompletedBuildStorageLocation !== '') && rec.data.JPACLineItem !== ''
      && existsWithLength(listRef.items)
    ) {
      rec.data.Status = '6. Complete';
      rec.data.PercentComplete = '100';
      rec.ModifiedBy = user.email;
      rec.ModifiedDate = new Date().toJSON();
      await SaveOrderEmail(rec);
    } else {
      toast.error(
        <div>You must fill out <strong>Completed Build Quantity</strong> {' '}
          and <strong>Completed Build Storage Location</strong> {' '}
          and <strong>Completed Build File Attachments</strong> to complete this request.
        </div>
        , { autoClose: 10000 });
      setSaving(false);
    }
  };

  const SaveAsDraft = () => {
    // if (editableOverride || ((user.admin || user.prefab || user.lead) && editableOverride2)) {
    //   toast.warning('Please save or discard your comments before saving your record.', { autoClose: 10000 });
    //   return;
    // }
    setSaving(true);
    const updatedRequest = { ...request };
    if (!user.admin && updatedRequest.SharedWith.indexOf(user.email) === -1) {
      updatedRequest.SharedWith.push(user.email);
    }
    updatedRequest.data.Status = 'Draft';
    updatedRequest.data.isWorkOrder = false;
    updatedRequest.ModifiedBy = user.email;
    updatedRequest.ModifiedDate = new Date().toJSON();
    setDoc(doc(firestore, 'RequestsOrders', updatedRequest.id), updatedRequest, { merge: true }).then(() => {
      setSaving(false);
      toast.success('Record Submitted Successfully.', { autoClose: 2000 });
      if (match.params.id === 'new') {
        history.push(`/requests/${request.id}`);
      }
    });
  };

  const StatusChangeEmailSend = async (rec) => {
    await SaveOrderEmail(rec);
  };

  const SaveCurrentRecord = () => {
    // if (editableOverride || ((user.admin || user.prefab || user.lead) && editableOverride2)) {
    //   toast.warning('Please save or discard your comments before saving your record.', { autoClose: 10000 });
    //   return;
    // }
    if (request.triggerEmailAlert) {
      handleSendComments();
    } else if (request.triggerPrivateEmailAlert) {
      handleSendComments();
    } else if (request.triggerEmailAlertNewUser) {
      handleNewUser();
    } else {
      setSaving(true);
      const updatedRequest = { ...request };
      updatedRequest.ModifiedBy = user.email;
      updatedRequest.ModifiedDate = new Date().toJSON();
      setDoc(doc(firestore, 'RequestsOrders', updatedRequest.id), updatedRequest, { merge: true }).then(() => {
        setSaving(false);
        toast.success('Record Submitted Successfully.', { autoClose: 2000 });
      });
    }

    if (StatusUpdated) {
      switch (request.data.Status) {
        case 'Approved':
          handleApproval();
          break;
        case 'Rejected':
          handleRejection();
          break;
        case 'Draft':
          SaveAsDraft();
          break;
        case '6. Completed':
          handleComplete();
          break;
        default:
          break;
      }
      // handleInProgress, handleOnHold, handleAwaitingMaterials, handleAwaitingScheduling have no unique function, record changes, email sent
      StatusChangeEmailSend(request);
      setStatusUpdated(false);
      console.log('saving status');
    }
  };
  const SubmitRequest = async () => {
    // if (editableOverride || ((user.admin || user.prefab || user.lead) && editableOverride2)) {
    //   toast.warning('Please save or discard your comments before saving your record.', { autoClose: 10000 });
    //   return;
    // }
    const validRequest = { ...request };
    const errors = await errorCheck(validRequest);
    if (errors.length === 0) {
      validRequest.data.Status = '1. Requested';
      if (!user.admin && validRequest.SharedWith.indexOf(user.email) === -1) {
        validRequest.SharedWith.push(user.email);
      }
      validRequest.ModifiedBy = user.email;
      validRequest.ModifiedDate = new Date().toJSON();
      let docRef;
      if (validRequest.id === '') {
        docRef = doc(collection(firestore, 'RequestsOrders'));
        validRequest.id = docRef.id;
      } else {
        docRef = doc(firestore, 'RequestsOrders', validRequest.id);
      }
      setSaving(true);
      await setDoc(docRef, validRequest, { merge: true });
      setSaving(false);
      toast.success('Record Submitted Successfully.', { autoClose: 2000 });
      // eslint-disable-next-line react/prop-types
      if (match.params.id === 'new') {
        history.push(`/requests/${request.id}`);
      }
      await SendStatusEmail(sendEmail, request, adminRoles.map(r => r.Lead[0]));
    } else {
      toast.error(<div>There are errors in this record, please correct them before approving the request.<br />Fields with missing/incorrect data: <strong>{errors.join(', ')}</strong></div>, { autoClose: 10000 });
    }
  };

  const MarkAsRead = () => {
    const rec = { ...request };
    rec.data.Notifications[user.email] = false;
    setDoc(doc(firestore, 'RequestsOrders', rec.id), rec).then(() => toast.success('Record marked as read.'));
  };
  return (<div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    position: 'sticky',
    top: 0,
    zIndex: 200,
    backgroundColor: 'white',
    padding: '10px 6px',
    width: '100%'
  }}
  >
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }}
    >
      {emailLoading ? <Grid container justifyContent="center"><TailSpin width={45} /></Grid> :
        <>
          <div>
            {['Draft', 'Rejected'].indexOf(request.data.Status) > -1 ?
              <Button variant='contained' color="primary" onClick={SubmitRequest} style={{ marginRight: '0.3rem' }}>Submit</Button>
              : null
            }
            {(user.admin || user.lead || user.prefab) && ['Approved', '4. In Progress', '5. On Hold', 'Awaiting Shipment'].indexOf(request.data.Status) > -1 ?
              <Button variant='contained' color="success" onClick={handleComplete} style={{ marginRight: '0.3rem' }}>Complete</Button>
              : null
            }
            {(user.admin || user.lead || user.prefab) && ['1. Requested'].indexOf(request.data.Status) > -1 ? <Button variant='contained' color="success" onClick={handleApproval} style={{ marginRight: '0.3rem' }}>Approve</Button> : null}
            {(user.admin || user.lead || user.prefab) && ['1. Requested'].indexOf(request.data.Status) > -1 ? <Button variant='contained' color="error" onClick={handleRejection} style={{ marginRight: '0.3rem' }}>Reject</Button> : null}
          </div>
          <div style={{ display: 'flex' }}>
            {(user.admin || user.lead || user.prefab) && request.data.Notifications?.[user.email] ? <ToolTipIconButton icon={faEnvelopeOpen} tooltipText="Mark This Record as Read" onClick={(e) => { e.stopPropagation(); MarkAsRead(); }} /> : null}
            <Button
              variant='contained'
              className='aldg-iconButtonWrapper aldg-iconContainer primaryContainer'
              title="Send Status Update Email"
              onClick={() => SendOrderUpdateEmail(sendEmail, request, adminRoles.map(r => r.Lead[0]))}
            >
              <span className="fa-layers fa-fw" style={{ width: '1.5rem' }}>
                <FontAwesomeIcon icon={faEnvelope} className='aldg-icon' />
                <FontAwesomeIcon icon={faShare} className='aldg-icon aldg-icon-duo' transform="shrink-6" style={{
                  position: 'absolute',
                  bottom: '-20px',
                  right: '-20px'
                }} />
              </span>
            </Button>
            {showPrev ? <Button
              variant='contained'
              className='aldg-iconButtonWrapper aldg-iconContainer primaryContainer'
              onClick={downloadPrev}
              title="Download PDF"
            >
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faFilePdf} className='aldg-icon' />
                <FontAwesomeIcon icon={faArrowDown} className='aldg-icon aldg-icon-duo' transform="shrink-6" style={{
                  position: 'absolute',
                  bottom: '-20px',
                  right: '-20px'
                }} />
              </span>
            </Button> : null}
            <Button
              variant='contained'
              className='aldg-iconButtonWrapper aldg-iconContainer primaryContainer'
              onClick={viewPrev}
              title="View PDF"
            >
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={faFilePdf} className='aldg-icon' />
                <FontAwesomeIcon icon={showPrev ? faEyeSlash : faEye} className='aldg-icon aldg-icon-duo' transform="shrink-6" style={{
                  position: 'absolute',
                  bottom: '-20px',
                  right: '-20px'
                }} />
              </span>
            </Button>
            <ToolTipIconButton icon={faSave} onClick={SaveCurrentRecord} color="success" tooltipText="Save Record" />
            <ToolTipIconButton icon={faTimes} onClick={() => history.push('/requests')} color="error" tooltipText="Close Record" />
          </div>
        </>
      }
    </div>
  </div >);
};

StatusButtons.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  request: PropTypes.objectOf(PropTypes.any).isRequired,
  setSaving: PropTypes.func.isRequired,
  setOrderNumber: PropTypes.func.isRequired,
  showPrev: PropTypes.bool.isRequired,
  viewPrev: PropTypes.func.isRequired,
  downloadPrev: PropTypes.func.isRequired,
  handleSendComments: PropTypes.func.isRequired,
  StatusUpdated: PropTypes.bool.isRequired,
  setStatusUpdated: PropTypes.func.isRequired,
  handleApproval: PropTypes.func.isRequired,
  handleRejection: PropTypes.func.isRequired,
  handleNewUser: PropTypes.func.isRequired,
  adminRoles: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
};

export default StatusButtons;