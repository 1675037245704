import PropTypes from 'prop-types';
import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Breadcrumb = (props) => {
  const { path } = props;
  const crumbs = [];

  for (let i = 0; i < path.length; i++) {
    let crumb;
    if (path[i].active) {
      crumb = (typeof path[i].onClick !== 'undefined') ? <Link href={path[i].link} key={path[i].name} onClick={path[i].onClick}>{path[i].name}</Link> : <Link to={path[i].link} key={path[i].name}>{path[i].name}</Link>;
    } else {
      crumb = <Typography key={path[i].name}>{path[i].name}</Typography>;
    };
    crumbs.push(crumb);
  }

  return (
    <div key='oof' style={{ margin: '8px 0px', padding: '12px 12px 12px 18px', backgroundColor: '#DADADA', borderRadius: '4px' }}>
      <Breadcrumbs separator=' / ' key='bread'>{crumbs}</Breadcrumbs>
    </div>
  );
};

Breadcrumb.propTypes = {
  path: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    active: PropTypes.bool,
    link: PropTypes.string,
    onClick: PropTypes.func
  }))
};
Breadcrumb.defaultProps = {
  path: []
};

export default Breadcrumb;
