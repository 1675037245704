/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect, useState,
} from 'react';
import { Button, Grid, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { DataGridPro, useGridApiRef, LicenseInfo } from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { collection, deleteDoc, doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { firestore } from '../firebase';
import QuickSearchToolbar from '../_helpers/QuickSearchToolbar';
import {
  _CurrentStatus, _CurrentJob, _CurrentOrderNumber, _CurrentSort
} from '../_Recoil/atoms';
import Breadcrumb from '../_GlobalComponents/Breadcrumbs';
import ConfirmDelete from '../_GlobalComponents/ConfirmDelete';
import { UserContext } from '../providers/UserProvider';

const RequestList = (props) => {

  LicenseInfo.setLicenseKey('42f6704089ac3d20afaba38a1115b415T1JERVI6MjczODYsRVhQSVJZPTE2NTg1MTUxNjAwMDAsS0VZVkVSU0lPTj0x');

  const { history } = props;
  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(false);
  const [selection, setSelection] = useState([]);
  const user = useContext(UserContext);

  useEffect(() => {
    let mounted = true;
    try {
      if (mounted)
        setLocationsLoading(true);
      onSnapshot(collection(firestore, 'Locations'), (querySnapshot) => {
        if (mounted)
          setLocationsLoading(true);
        const allRequests = [];
        querySnapshot.forEach((d) => {
          allRequests.push(d.data());
        });
        if (mounted)
          setLocations(allRequests);
      }, (err) => {
        toast.error(err.message);
      });
    } catch (err) {
      toast.error(err.message);
    }
    return () => { mounted = false; };
  }, []);

  const deleteRecords = () => {
    selection.forEach(s => {
      deleteDoc(doc(firestore, 'Locations', s));
    });
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const getFlex = (f) => (matches ? null : f);
  const getWidth = (w) => (matches ? w : null);

  const columns = [
    {
      field: 'LocationName',
      headerName: 'LocationName',
      valueGetter: (params) => params.row.data.LocationName,
      flex: getFlex(15),
      width: getWidth(150),
      sortable: true
    }, {
      field: 'Status',
      headerName: 'Status',
      valueGetter: (params) => params.row.data.Status,
      flex: getFlex(15),
      width: getWidth(150),
      sortable: true
    }
  ];

  const handleSelect = (idx) => {
    history.push(`/locations/${idx}`);
  };

  const apiRef = useGridApiRef();

  return (
    <>
      <Breadcrumb path={[{ name: 'Locations', active: false }]} />
      <Grid container>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='contained'
            className='aldg-iconButtonWrapper aldg-iconContainer primaryContainer'
            LinkComponent={Link}
            to="/locations/new"
          >
            <FontAwesomeIcon icon={faPlus} className='aldg-icon' />
          </Button>
          <Button
            variant='contained'
            className='aldg-iconButtonWrapper aldg-iconContainer errorContainer'
            onClick={deleteRecords}
          >
            <FontAwesomeIcon icon={faTrashAlt} className='aldg-icon' />
          </Button>
        </Grid>
      </Grid>
      <div style={{ padding: '0 10px', overflowX: 'auto', backgroundColor: 'white' }}>
        <Grid container>
          <Grid item xs={12}>
            <DataGridPro
              editMode='row'
              autoHeight
              checkboxSelection
              getRowId={(r) => r.id}
              loading={locationsLoading}
              onSelectionModelChange={(newSelection) => setSelection(newSelection)}
              onRowClick={(row) => handleSelect(row.id)}
              selectionModel={selection}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: '',
                },
              }}
              pagination
              rowsPerPageOptions={[10, 25, 50]}
              pageSize={50}
              apiRef={apiRef}
              rows={locations}
              columns={columns}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

RequestList.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number,
    action: PropTypes.string,
    location: PropTypes.objectOf(PropTypes.string, PropTypes.object),
    push: PropTypes.func,
    replace: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    block: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.object),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string
  }),
};
RequestList.defaultProps = {
  history: {
    push: () => { }
  },
  match: {
    url: ''
  }
};

export default RequestList;
