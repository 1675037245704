import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, CardHeader } from '@mui/material';
import { InputRadio, InputText } from '@aldridge/aldg-data-components';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { collection, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { firestore } from '../firebase';
import Breadcrumb from '../_GlobalComponents/Breadcrumbs';
import { UserContext } from '../providers/UserProvider';

const PreFabLocation = (props) => {
  const { match, history } = props;
  const user = useContext(UserContext);
  const [location, setLocation] = useState({
    id: '',
    CreatedDate: '',
    CreatedBy: '',
    ModifiedDate: '',
    ModifiedBy: '',
    data: {
      LocationName: '',
      Status: 'Active'
    }
  });
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const RECORDID = (() => {
      if (match.params.id === 'new') {
        const newRecordId = (doc(collection(firestore, 'Locations'))).id;
        history.push(`/locations/${newRecordId}`);
        return newRecordId;
      }
      return match.params.id;
    })();

    try {
      setLoading(true);
      onSnapshot(doc(firestore, 'Locations', RECORDID), (snap) => {
        if (snap.exists()) {
          const record = snap.data();
          setLocation(record);
        } else {
          const record = { ...location, id: RECORDID };
          setLocation(record);
        }
        setLoading(false);
      }, (err) => {
        toast.error(err.message);
      });
    } catch (err) {
      toast.error(err.message);
    }
  }, []);

  const handleChange = (event, name, displayFromTypeahead) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedLocation = { ...location };

      changedLocation.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedLocation.data[`${id}Display`] = displayFromTypeahead;
      }
      if (changedLocation.CreatedBy === '') {
        changedLocation.CreatedBy = user.email;
        changedLocation.CreatedDate = new Date().toJSON();
      }
      changedLocation.ModifiedBy = user.email;
      changedLocation.ModifiedDate = new Date().toJSON();
      setLocation(changedLocation);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const save = () => {
    setSaving(true);
    setDoc(doc(firestore, 'Locations', location.id), location, { merge: true }).then(() => {
      setSaving(false);
      toast.success('Location Saved Successfully.');
      history.push('/locations');
    })
      .catch((err) => {
        setSaving(false);
        toast.success(`Location Failed to Save. ${err.message}`);
      });
  };

  return (
    <>
      {
        saving ? (
          <div style={{
            display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 100
          }}
          />
        ) : null
      }
      <Grid container style={{ backgroundColor: 'white', padding: '12px' }}>
        <Grid item xs={12}>
          <Breadcrumb path={[{ name: 'Locations', active: true, link: '/locations' }, { name: `Location: ${location?.data?.LocationName}`, active: false }]} />
        </Grid>
        <Grid item xs={12}>
          <CardHeader title="Location">Location</CardHeader>
        </Grid>
        <Grid item xs={12}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexWrap: 'wrap',
            position: 'sticky',
            top: 0,
            zIndex: 200,
            backgroundColor: 'white',
            padding: '10px 0'
          }}
          >
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
            >
              <Button
                variant='contained'
                className='aldg-iconButtonWrapper aldg-iconContainer successContainer'
                onClick={save}
              >
                <FontAwesomeIcon icon={faSave} className='aldg-icon' />
              </Button>
              <Button
                variant='contained'
                className='aldg-iconButtonWrapper aldg-iconContainer errorContainer'
                LinkComponent={Link}
                to="/locations"
              >
                <FontAwesomeIcon icon={faTimes} className='aldg-icon' />
              </Button>
            </div>
          </div>
        </Grid>
        {
          loading
            ? (
              <div style={{
                display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
              }}
              >
                <TailSpin
                  color="#3b78e7"
                  height={100}
                  width={100}
                />
                <div>Loading Location Data...</div>
              </div>
            )
            : (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputText
                      label="Location Name"
                      name="LocationName"
                      value={location.data.LocationName || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputRadio
                      radios={[
                        { value: 'Active', label: 'Active' },
                        { value: 'Inactive', label: 'Inactive' }
                      ]}
                      FormControlLabelProps={{
                        label: 'Status'
                      }}
                      RadioGroupProps={{
                        name: 'Status',
                        onChange: handleChange,
                        value: location.data.Status || '',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
        }
      </Grid>
    </>
  );
};

PreFabLocation.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number,
    action: PropTypes.string,
    location: PropTypes.objectOf(PropTypes.string, PropTypes.object),
    push: PropTypes.func,
    replace: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    block: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.any),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string
  }),
};
PreFabLocation.defaultProps = {
  history: {
    push: () => { }
  },
  match: {
    url: ''
  }
};

export default PreFabLocation;
