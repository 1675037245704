// /* eslint-disable no-await-in-loop */
// /* eslint-disable no-restricted-globals */
// /* eslint-disable no-unused-vars */
// /* eslint-disable max-len */
// import PropTypes from 'prop-types';
// import React, {
//   useEffect, useRef, useState
// } from 'react';
// import {
//   Button,
//   Table,
// } from '@mui/material';
// import dayjs from 'dayjs';
// import { toast } from 'react-toastify';
// import csv from 'csv';
// import {
//   collection,
//   getDocs,
//   query,
//   where
// } from 'firebase/firestore';
// import {TailSpin} from 'react-loader-spinner';
// import { useFirebaseMutation } from '../_helpers/fetch';
// import {
//   _CurrentStatus, _CurrentJob, _CurrentOrderNumber, _CurrentSort
// } from '../_Recoil/atoms';
// import { firestore } from '../firebase';

const TimeUpload = () => {
  //   const [goodObjects, setGoodObjects] = useState([]);
  //   const [badObjects, setBadObjects] = useState([]);
  //   const [errors, setErrors] = useState([]);
  //   const [LoadingRecords, setLoadingRecords] = useState(false);
  //   const hiddenFileInput = useRef(null);
  //   const uploadFile = () => {
  //     hiddenFileInput.current.click();
  //   };

  //   const {
  //     firebaseCall: submitTime,
  //     loading: submitTimeLoading,
  //     error: submitTimeError,
  //     data: submitTimeData
  //   } = useFirebaseMutation('submitTime', { timeRecords: goodObjects });
  //   const csvJSON = (c) => {
  //     const lines = c.split('\n');
  //     const result = [];

  //     // NOTE: If your columns contain commas in their values, you'll need
  //     // to deal with those before doing the next step
  //     // (you might convert them to &&& or something, then covert them back later)
  //     // jsfiddle showing the issue https://jsfiddle.net/
  //     const headers = lines[0].split(',');
  //     for (let i = 1; i < lines.length; i++) {
  //       const obj = {};
  //       const currentline = lines[i].split(',');
  //       for (let j = 0; j < headers.length; j++) {
  //         obj[headers[j]] = currentline[j];
  //       }
  //       result.push(obj);
  //     }

  //     // return result; //JavaScript object
  //     return result; // JSON
  //   };
  //   useEffect(() => {
  //     if (typeof submitTimeError !== 'undefined') {
  //       toast.error('Something has happened. Email the file to Travis for debugging...');
  //     }
  //   }, [submitTimeLoading, submitTimeError, submitTimeData]);

  //   const createTimeObjects = async (json) => {
  //     const template = {
  //       key: {
  //         recordStatus: {
  //           value: '', display: '', default: '', disabled: false
  //         },
  //         recordId: {
  //           value: '', display: '', default: '', disabled: false
  //         },
  //         templateId: {
  //           value: '', display: '', default: '', disabled: false
  //         },
  //         templateFor: {
  //           value: '', display: '', default: '', disabled: false
  //         },
  //         aldId: {
  //           value: '', display: '', default: '', disabled: false
  //         },
  //         pid: {
  //           value: '', display: '', default: '', disabled: false
  //         },
  //       },
  //       data: {
  //         meta: {
  //           weekEndDate: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           currentTimecard: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           mon: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           tue: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           wed: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           thu: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           fri: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           sat: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           sun: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           noRecordedWork: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           company: {
  //             value: '1', display: '1 - Aldridge Electric', default: '', disabled: false
  //           },
  //           department: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           glAccount: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           divisionSupported: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           division: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           job: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           subJob: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           picc: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           workUnion: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           shift: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           regular: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           overtime: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           holiday: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           vacation: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           doubletime: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           sickLeaveType: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           sickLeave: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           perDiem: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           exemptFromCertified: {
  //             value: true, display: '', default: '', disabled: false
  //           },
  //           equipmentNumber: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           componentNumber: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           workOrderNumber: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           workOrderLineNumber: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           approved: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           lessThan8HoursVerified: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           lastModified: {
  //             value: '', display: '', default: '', disabled: false
  //           },
  //           lastModifiedBy: {
  //             value: '', display: '', default: '', disabled: false
  //           }
  //         },
  //         ignoreMe: {
  //           hasPerDiem: {
  //             value: '', display: '', default: '', disabled: false
  //           }
  //         }
  //       }
  //     };
  //     const GLCodeFormat = (glcode) => {
  //       const { gc } = glcode;
  //       if (gc.split('/').length > 1) {
  //         return `${gc.split('/')[1]}${gc.split('/')[0]}00000000`;
  //       }
  //       return null;
  //     };
  //     const whatDayIsIt = (day, obj) => {
  //       switch (day.toLowerCase().trim()) {
  //         case 'monday':
  //           obj.data.meta.mon.value = true;
  //           obj.data.meta.mon.display = day;
  //           break;
  //         case 'tuesday':
  //           obj.data.meta.tue.value = true;
  //           obj.data.meta.tue.display = day;
  //           break;
  //         case 'wednesday':
  //           obj.data.meta.wed.value = true;
  //           obj.data.meta.wed.display = day;
  //           break;
  //         case 'thursday':
  //           obj.data.meta.thu.value = true;
  //           obj.data.meta.thu.display = day;
  //           break;
  //         case 'friday':
  //           obj.data.meta.fri.value = true;
  //           obj.data.meta.fri.display = day;
  //           break;
  //         case 'saturday':
  //           obj.data.meta.sat.value = true;
  //           obj.data.meta.sat.display = day;
  //           break;
  //         case 'sunday':
  //           obj.data.meta.sun.value = true;
  //           obj.data.meta.sun.display = day;
  //           break;
  //         default:
  //           break;
  //       }
  //     };
  //     const EmpRecords = [];
  //     const OrderNumbers = [];
  //     const PiccRecords = [];
  //     const JobRecords = [];
  //     const TIMERECORDS = [];
  //     const FAILEDTIMERECORDS = [];
  //     const ERRORS = [];
  //     for (let i = 0; i < json.length; i++) {
  //       const obj = JSON.parse(JSON.stringify(template));
  //       obj.data.meta.weekEndDate.value = json[i]['Week Ending Date'];
  //       obj.data.meta.weekEndDate.display = dayjs(json[i]['Week Ending Date']).format('MMM DD, YYYY');
  //       whatDayIsIt(json[i].Day, obj);

  //       obj.data.meta.currentTimecard.value = 'field';
  //       obj.data.meta.currentTimecard.display = 'Field';

  //       obj.data.meta.regular.value = json[i].ST;
  //       obj.data.meta.regular.display = json[i].ST;
  //       obj.data.meta.overtime.value = json[i].OT;
  //       obj.data.meta.overtime.display = json[i].OT;
  //       obj.data.meta.doubletime.value = json[i].DT || '';
  //       obj.data.meta.doubletime.display = json[i].DT || '';

  //       const empRecord = await (async () => {
  //         try {
  //           if (EmpRecords.filter((res) => res.EmployeeNumber === json[i]['Employee Number']).length > 0) {
  //             return EmpRecords.filter((res) => res.EmployeeNumber === json[i]['Employee Number'])[0];
  //           }
  //           const data = await getDocs(query(collection(firestore, 'Employees'), where('EmployeeNumber', '==', json[i]['Employee Number'])));
  //           EmpRecords.push(data.docs[0].data());
  //           return data.docs[0].data();
  //         } catch (err) {
  //           ERRORS.push(`Failed to Get Employee Info for ${json[i]['Employee Number']}. Either this Employee doesn't exist or was entered incorrectly. If this employee was terminated and they had a check cut already, remove them from the file and try again.`);
  //           return null;
  //         }
  //       })();
  //       const orderRecord = await (async () => {
  //         try {
  //           if (OrderNumbers.filter((res) => res.data.OrderNumber === json[i]['Order Number']).length > 0) {
  //             return OrderNumbers.filter((res) => res.data.OrderNumber === json[i]['Order Number'])[0];
  //           }
  //           const data = await getDocs(query(collection(firestore, 'RequestsOrders'), where('data.OrderNumber', '==', json[i]['Order Number'])));
  //           OrderNumbers.push(data.docs[0].data());
  //           return data.docs[0].data();
  //         } catch (err) {
  //           ERRORS.push(`Failed to Get Order Info for ${json[i]['Order Number']}. Either this Order Number doesn't exist or was entered incorrectly.`);
  //           return null;
  //         }
  //       })();
  //       let piccRecord = null;
  //       let jobRecord = null;
  //       if (orderRecord !== null) {
  //         if (orderRecord.data.IsGLTicket) {
  //           if (orderRecord.data.GLCode?.split('/').length > 1) {
  //             obj.data.meta.glAccount.value = GLCodeFormat(orderRecord.data.GLCode);
  //             // eslint-disable-next-line prefer-destructuring
  //             obj.data.meta.department.value = orderRecord.data.DepartmentNumber[0];
  //             // eslint-disable-next-line prefer-destructuring
  //             obj.data.meta.department.display = orderRecord.data.DepartmentNumber[0];
  //           } else {
  //             ERRORS.push(`Failed to Get GL Code for ${json[i]['Order Number']}. Either this Order Number doesn't have a GL Code associated or doesn't fit the XXX/XXXX format.`);
  //           }
  //         } else {
  //           piccRecord = await (async () => {
  //             try {
  //               if (PiccRecords.filter((res) => res.jctmstid === orderRecord.data.Picc[0]).length > 0) {
  //                 return PiccRecords.filter((res) => res.jctmstid === orderRecord.data.Picc[0])[0];
  //               }
  //               const data = await getDocs(query(collection(firestore, 'Piccs'), where('jctmstid', '==', orderRecord.data.Picc[0])));
  //               PiccRecords.push(data.docs[0].data());
  //               return data.docs[0].data();
  //             } catch (err) {
  //               ERRORS.push(`Failed to Get PICC Info on Order Number ${json[i]['Order Number']}. Either this PICC doesn't exist or was entered incorrectly in the Order.`);
  //               return null;
  //             }
  //           })();
  //           jobRecord = await (async () => {
  //             try {
  //               if (JobRecords.filter((res) => res.jctdscid === orderRecord.data.JobNumber[0]).length > 0) {
  //                 return JobRecords.filter((res) => res.jctdscid === orderRecord.data.JobNumber[0])[0];
  //               }
  //               const data = await getDocs(query(collection(firestore, 'Jobs'), where('jctdscid', '==', orderRecord.data.JobNumber[0])));
  //               JobRecords.push(data.docs[0].data());
  //               return data.docs[0].data();
  //             } catch (err) {
  //               ERRORS.push(`Failed to Get Job Info for Order Number ${json[i]['Order Number']}. Either this Job doesn't exist or was entered incorrectly.`);
  //               return null;
  //             }
  //           })();

  //           obj.data.meta.job.value = jobRecord?.JobNumber;
  //           obj.data.meta.job.display = `${jobRecord?.JobNumber} - ${jobRecord?.JobDescription}`;
  //           obj.data.meta.subJob.value = jobRecord?.SubJobNumber;
  //           obj.data.meta.subJob.display = `${jobRecord?.SubJobNumber} - ${jobRecord?.JobDescription}`;
  //           obj.data.meta.picc.value = piccRecord?.PICC;
  //           obj.data.meta.picc.display = `${piccRecord?.PICC} - ${piccRecord?.Description}`;
  //           obj.data.meta.department.value = `${empRecord?.['Department Number']}`;
  //           obj.data.meta.department.display = `${empRecord?.['Department Number']}`;
  //         }
  //       }
  //       obj.key.pid.value = empRecord?.prtmstid;
  //       obj.key.pid.display = `${empRecord?.LegalFirstName} ${empRecord?.LegalLastName}`;
  //       obj.data.meta.workUnion.value = empRecord?.Union;
  //       obj.data.meta.workUnion.display = `${empRecord?.Union}`;

  //       obj.data.meta.shift.value = '1';
  //       obj.data.meta.shift.display = '1';

  //       obj.data.ignoreMe.orderNumber = json[i]['Order Number'];
  //       const finalObj = {
  //         recordId: '',
  //         pid: empRecord?.prtmstid,
  //         currentTimecard: 'field',
  //         weekEndDate: json[i]['Week Ending Date'],
  //         mon: obj.data.meta.mon.value,
  //         tue: obj.data.meta.tue.value,
  //         wed: obj.data.meta.wed.value,
  //         thu: obj.data.meta.thu.value,
  //         fri: obj.data.meta.fri.value,
  //         sat: obj.data.meta.sat.value,
  //         sun: obj.data.meta.sun.value,
  //         company: '1',
  //         department: obj.data.meta.department.value,
  //         glAccount: obj.data.meta.glAccount.value,
  //         job: jobRecord?.JobNumber,
  //         subJob: jobRecord?.SubJobNumber,
  //         picc: piccRecord?.PICC,
  //         shift: 1,
  //         workUnion: empRecord?.Union,
  //         regular: json[i].ST,
  //         overtime: json[i].OT,
  //         doubletime: json[i].DT || '',
  //         orderNumber: json[i]['Order Number'],
  //         json: JSON.parse(JSON.stringify(obj))
  //       };
  //       if (orderRecord !== null && (orderRecord.data.IsGLTicket === false || typeof orderRecord.data.IsGLTicket === 'undefined') && jobRecord !== null && piccRecord !== null && empRecord !== null && orderRecord !== null) {
  //         TIMERECORDS.push(finalObj);
  //       } else if (orderRecord !== null && orderRecord.data.IsGLTicket && empRecord !== null) {
  //         TIMERECORDS.push(finalObj);
  //       } else {
  //         FAILEDTIMERECORDS.push(finalObj);
  //       }
  //     }
  //     return { good: TIMERECORDS, bad: FAILEDTIMERECORDS, errors: ERRORS };
  //   };
  //   const handleChange = (event) => {
  //     const fileUploaded = event.target.files[0];
  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       setLoadingRecords(true);
  //       const obj = csv.parse(reader.result, async (err, data) => {
  //         const headers = data.shift();
  //         const newData = data.map((res) => ({
  //           [headers[0]]: res[0],
  //           [headers[1]]: res[1],
  //           [headers[2]]: res[2],
  //           [headers[3]]: res[3],
  //           [headers[4]]: res[4],
  //           [headers[5]]: res[5],
  //           [headers[6]]: res[6],
  //           [headers[7]]: res[7],
  //           [headers[8]]: res[8],
  //         }));
  //         const r = await createTimeObjects(newData);
  //         setGoodObjects(r.good);
  //         setBadObjects(r.bad);
  //         setErrors(r.errors);
  //         setLoadingRecords(false);
  //       });
  //     };
  //     if (typeof fileUploaded !== 'undefined') { reader.readAsText(fileUploaded); }
  //   };
  //   const showDay = (record) => {
  //     if (record.json.data.meta.mon.value) {
  //       return record.json.data.meta.mon.display;
  //     }
  //     if (record.json.data.meta.tue.value) {
  //       return record.json.data.meta.tue.display;
  //     }
  //     if (record.json.data.meta.wed.value) {
  //       return record.json.data.meta.wed.display;
  //     }
  //     if (record.json.data.meta.thu.value) {
  //       return record.json.data.meta.thu.display;
  //     }
  //     if (record.json.data.meta.fri.value) {
  //       return record.json.data.meta.fri.display;
  //     }
  //     if (record.json.data.meta.sat.value) {
  //       return record.json.data.meta.sat.display;
  //     }
  //     if (record.json.data.meta.sun.value) {
  //       return record.json.data.meta.sun.display;
  //     }
  //     return null;
  //   };
  //   const submit = () => {
  //     submitTime();
  //   };
  //   return (
  //     <>
  //       <h1>Not In Production Yet</h1>
  //       <Button onClick={uploadFile}>Upload Time</Button>
  //       <input
  //         type="file"
  //         ref={hiddenFileInput}
  //         onChange={handleChange}
  //         style={{ display: 'none' }}
  //       />
  //       {
  //         errors.length > 0
  //           ? (
  //             <>
  //               <h5>There were errors in the upload...</h5>
  //               <ul>
  //                 {errors.map((res) => (<li>{res}</li>))}
  //               </ul>
  //             </>
  //           )
  //           : null
  //       }
  //       {
  //         errors.length === 0 && goodObjects.length > 0 && badObjects.length === 0
  //           ? <h5>All records are ready for upload. No errors in file.</h5>
  //           : null
  //       }
  //       {errors.length === 0 && goodObjects.length > 0 && badObjects.length === 0 ? <Button color="primary" onClick={submit}>Submit Time</Button> : null}
  //       <div style={{
  //         display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'
  //       }}
  //       >
  //         {LoadingRecords ? <><TailSpin type="TailSpin" /><div>Preparing Time Records...</div></> : null}
  //         {submitTimeLoading ? <><TailSpin type="TailSpin" /><div>Submitting Time Records...</div></> : null}

  //       </div>
  //       {
  //         goodObjects.length > 0
  //           ? (
  //             <>
  //               <h4 style={{ textAlign: 'center' }}>{goodObjects.length > 0 ? `Uploading ${goodObjects.length} Time Entries to Time for Week Ending ${goodObjects[0].json.data.meta.weekEndDate.display}` : null}</h4>
  //               <Table>
  //                 <thead>
  //                   <tr>
  //                     <th>Employee</th>
  //                     <th>Day</th>
  //                     <th>Order Number</th>
  //                     <th>Job / Sub Job [or Department]</th>
  //                     <th>PICC [or GL Code]</th>
  //                     <th>Std Hours</th>
  //                     <th>Ovt Hours</th>
  //                     <th>Dbl Hours</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   {goodObjects.map((res) => (
  //                     <tr>
  //                       <td>{res.json.key.pid.display}</td>
  //                       <td>{showDay(res)}</td>
  //                       <td>{res.orderNumber}</td>
  //                       <td>{res.glAccount === '' ? `${res.job}.${res.subJob} - ${res.json.data.meta.job.display.split(' - ')[1]}` : res.department}</td>
  //                       <td>{res.glAccount === '' ? res.json.data.meta.picc.display : res.glAccount}</td>
  //                       <td>{res.regular}</td>
  //                       <td>{res.overtime}</td>
  //                       <td>{res.doubletime}</td>
  //                     </tr>
  //                   ))}
  //                 </tbody>
  //               </Table>
  //             </>
  //           )
  //           : null
  //       }

  //     </>
  //   );
  // };

  // TimeUpload.propTypes = {
  //   history: PropTypes.shape({
  //     length: PropTypes.number,
  //     action: PropTypes.string,
  //     location: PropTypes.objectOf(PropTypes.string, PropTypes.object),
  //     push: PropTypes.func,
  //     replace: PropTypes.func,
  //     go: PropTypes.func,
  //     goBack: PropTypes.func,
  //     goForward: PropTypes.func,
  //     block: PropTypes.func
  //   }),
  //   match: PropTypes.shape({
  //     params: PropTypes.objectOf(PropTypes.object),
  //     isExact: PropTypes.bool,
  //     path: PropTypes.string,
  //     url: PropTypes.string
  //   }),
  // };
  // TimeUpload.defaultProps = {
  //   history: {
  //     push: () => { }
  //   },
  //   match: {
  //     url: ''
  //   }
};

export default TimeUpload;
