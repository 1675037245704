/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Grid } from '@mui/material';
// import { Files, InputText, InputTextarea, RepeatableList } from '@aldridge/aldg-data-components';
import { AldgDataGrid, AldgQuickAdd, Files, InputText, InputTextarea, RepeatableList } from '@aldridge/aldg-data-components';
import dayjs from 'dayjs';
import { existsWithLength } from '@aldridge/aldg-helpers';
import { useGridApiRef } from '@mui/x-data-grid-pro';
// import AldgDataGrid from '../../../_GlobalComponents/AldgDataGrid/AldgDataGrid';
import { config } from '../../../firebase';


const OrderCompletionInformation = (props) => {
  const { request, handleChange, user, hasEditAccess, editableOverride, setEditableOverride, editableOverride2, setEditableOverride2 } = props;
  const publicApiRef = useGridApiRef();
  const privateApiRef = useGridApiRef();

  const [PublicCurrentRecord, setPublicCurrentRecord] = useState({
    ModifiedDate: '',
    body: '',
    cby: '',
    cdt: '',
    id: '',
    mby: '',
    mdt: ''
  });
  const [PrivateCurrentRecord, setPrivateCurrentRecord] = useState({
    ModifiedDate: '',
    body: '',
    cby: '',
    cdt: '',
    id: '',
    mby: '',
    mdt: ''
  });
  const UpdatePublicRow = (row) => {
    console.log(row);
    setPublicCurrentRecord({
      ...row
    });
  };
  const handlePublicSave = () => {
    const id = existsWithLength(PublicCurrentRecord.id) ? PublicCurrentRecord.id : Date.now().toString();
    const uPublic = JSON.parse(JSON.stringify(PublicCurrentRecord));
    uPublic.id = id;
    uPublic.cby = !existsWithLength(uPublic.cby) ? user.email : uPublic.cby;
    uPublic.cdt = !existsWithLength(uPublic.cdt) ? new Date().toJSON() : uPublic.cdt;
    uPublic.mby = user.email;
    uPublic.mdt = new Date().toJSON();

    const PublicNotes = JSON.parse(JSON.stringify(request.data.PublicNotes));
    const existingNote = PublicNotes.findIndex(r => r.id === id);
    if (existingNote > -1) {
      PublicNotes[existingNote] = uPublic;
    } else {
      PublicNotes.push(uPublic);
    }
    handleChange(PublicNotes, "PublicNotes");

    setPublicCurrentRecord({
      ModifiedDate: '',
      body: '',
      cby: '',
      cdt: '',
      id: '',
      mby: '',
      mdt: ''
    });
  };
  const handlePublicCancel = () => {
    setPublicCurrentRecord({
      ModifiedDate: '',
      body: '',
      cby: '',
      cdt: '',
      id: '',
      mby: '',
      mdt: ''
    });
  };


  const UpdatePrivateRow = (row) => {
    console.log(row);
    setPrivateCurrentRecord({
      ...row
    });
  };
  const handlePrivateSave = () => {
    const id = existsWithLength(PrivateCurrentRecord.id) ? PrivateCurrentRecord.id : Date.now().toString();
    const uPrivate = JSON.parse(JSON.stringify(PrivateCurrentRecord));
    uPrivate.id = id;
    uPrivate.cby = !existsWithLength(uPrivate.cby) ? user.email : uPrivate.cby;
    uPrivate.cdt = !existsWithLength(uPrivate.cdt) ? new Date().toJSON() : uPrivate.cdt;
    uPrivate.mby = user.email;
    uPrivate.mdt = new Date().toJSON();

    const PrivateNotes = JSON.parse(JSON.stringify(request.data.PrivateNotes));
    const existingNote = PrivateNotes.findIndex(r => r.id === id);
    if (existingNote > -1) {
      PrivateNotes[existingNote] = uPrivate;
    } else {
      PrivateNotes.push(uPrivate);
    }
    handleChange(PrivateNotes, "PrivateNotes");

    setPrivateCurrentRecord({
      ModifiedDate: '',
      body: '',
      cby: '',
      cdt: '',
      id: '',
      mby: '',
      mdt: ''
    });
  };
  const handlePrivateCancel = () => {
    setPrivateCurrentRecord({
      ModifiedDate: '',
      body: '',
      cby: '',
      cdt: '',
      id: '',
      mby: '',
      mdt: ''
    });
  };

  const columns = [
    {
      field: 'body',
      headerName: 'Comment',
      valueGetter: (params) => params.row.body,
      flex: 20,
      canEdit: true,
      inputType: 'InputTextarea',
      hideable: false,
      onChangeDependents: ['mby', 'cdt', 'mdt']
    },
    {
      field: 'cby',
      headerName: 'Added By',
      defaultValue: user.email,
      valueGetter: (params) => params.row.cby,
      flex: 10,
      hideable: false
    },
    {
      field: 'mby',
      headerName: 'Modified By',
      defaultValue: user.email,
      valueGetter: (params) => params.row.mby,
      flex: 10,
      hideable: false
    },
    {
      field: 'cdt',
      headerName: 'Added On',
      valueGetter: (params) => params.row.cdt,
      valueFormatter: (params) => params.value !== '' ? dayjs(params.value).tz('America/Chicago').format('llll') : '',
      flex: 10,
      hideable: false
    },
    {
      field: 'mdt',
      headerName: 'Modified On',
      valueGetter: (params) => params.row.mdt,
      valueFormatter: (params) => dayjs(params.value).tz('America/Chicago').format('llll'),
      flex: 10,
      hideable: false
    }
  ];

  return (
    <Card><CardHeader title={['Draft', 'Rejected', '1. Requested'].indexOf(request.data.Status) > -1 ? null : 'Order Completion Information'} /> <CardContent><Grid container>
      <Grid item className='aldg-field' xs={12} >
        <Files
          title="Design Documentation and Bill of Material"
          folder={`${request.id}/DesignPhotos`}
          user={user}
          firebaseConfig={config}
          type="list"
          defaultOpen
          canAdd={hasEditAccess}
          canDelete={hasEditAccess}
          multiple
        />
      </Grid>
      {['Draft', 'Rejected', '1. Requested'].indexOf(request.data.Status) > -1 ? null :
        <Grid item className='aldg-field' xs={12} style={{ margin: '1.25rem 0' }}>
          <Files
            title="In Progress Build Attachments"
            folder={`${request.id}/InProgressBuildAttachments`}
            user={user}
            firebaseConfig={config}
            type="list"
            defaultOpen
            canAdd={user.admin || user.lead}
            canDelete={user.admin || user.lead}
            multiple
          />
        </Grid>}
      {['Draft', 'Rejected', '1. Requested'].indexOf(request.data.Status) > -1 ? null :
        <Grid item className='aldg-field' xs={12} md={6}>
          <InputText
            label="Completed Build Quantity"
            name="CompletedBuildQuantity"
            onChange={handleChange}
            value={request.data.CompletedBuildQuantity || ''}
            disabled={!user.admin && !user.lead}
          />
        </Grid>}
      {['Draft', 'Rejected', '1. Requested'].indexOf(request.data.Status) > -1 ? null :
        <Grid item className='aldg-field' xs={12} md={6}>
          <InputTextarea
            label="Completed Build Storage Location"
            onChange={handleChange}
            name="CompletedBuildStorageLocation"
            value={request.data.CompletedBuildStorageLocation || ''}
            disabled={!user.admin && !user.lead}
          />
        </Grid>}

      {['Draft', 'Rejected', '1. Requested'].indexOf(request.data.Status) > -1 ? null :
        <Grid item className='aldg-field' xs={12} style={{ margin: '1.25rem 0' }}>
          <Files
            title="Completed Build Attachments"
            folder={`${request.id}/CompletedBuildAttachments`}
            user={user}
            firebaseConfig={config}
            type="list"
            defaultOpen
            canAdd={hasEditAccess}
            canDelete={hasEditAccess}
            multiple
          />
        </Grid>}
      <Grid item className='aldg-field' xs={12}>
        <AldgQuickAdd
          DataGridProps={{
            apiRef: publicApiRef,
            pagination: true,
            getRowID: (r) => r.id,
            onCellClick: (cell, event) => {
              if (cell.field === 'Delete') {
                event.preventDefault();
                event.stopPropagation();
              }
            }
          }}
          anchor='right'
          updateRow={UpdatePublicRow}
          columns={columns}
          rows={request.data.PublicNotes}
          handleSave={handlePublicSave}
          handleCancel={handlePublicCancel}
          form={
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={1}>
              <Grid item xs={12}>
                <InputTextarea
                  fullWidth
                  label='Note'
                  value={PublicCurrentRecord.body || ''}
                  onChange={(e) => {
                    setPublicCurrentRecord({ ...PublicCurrentRecord, body: e });
                  }}
                  id='fullWidth'
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      {user.lead || user.admin ?
        <Grid item className='aldg-field' xs={12}>
          <AldgQuickAdd
            DataGridProps={{
              apiRef: privateApiRef,
              pagination: true,
              getRowID: (r) => r.id,
              onCellClick: (cell, event) => {
                if (cell.field === 'Delete') {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }
            }}
            anchor='right'
            updateRow={UpdatePrivateRow}
            columns={columns}
            rows={request.data.PrivateNotes}
            handleSave={handlePrivateSave}
            handleCancel={handlePrivateCancel}
            form={
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={1}>
                <Grid item xs={12}>
                  <InputTextarea
                    fullWidth
                    label='Note'
                    value={PrivateCurrentRecord.body || ''}
                    onChange={(e) => {
                      setPrivateCurrentRecord({ ...PrivateCurrentRecord, body: e });
                    }}
                    id='fullWidth'
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid> : null}
    </Grid>
    </CardContent></Card>
  );
};


OrderCompletionInformation.propTypes = {
  request: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChange: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
  editableOverride: PropTypes.bool.isRequired,
  setEditableOverride: PropTypes.func.isRequired,
  editableOverride2: PropTypes.bool.isRequired,
  setEditableOverride2: PropTypes.func.isRequired
};
OrderCompletionInformation.defaultProps = {};


export default OrderCompletionInformation;