import React from 'react';
import { Button, Paper, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';

const NoMatch = () => {
  const history = useHistory();
  return (
    <Paper>
      <Grid container spacing={1} style={{ padding: '8px' }}>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <FontAwesomeIcon size='8x' icon={faQuestionCircle} color='#1E3050' />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Typography variant='h5' align='center'>
            This Page Cannot be Found.
          </Typography>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={3} />
        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <Button
            type='button'
            variant='contained'
            onClick={() => {
              history.push('/');
            }}
          >
            Go Home
          </Button>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </Paper>
  );
};
export default NoMatch;
