import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const RequestPDF = (props) => {
  const { request } = props;

  const parseStatus = (stat) => {
    if ('1234567890'.indexOf(stat.substring(0, 1)) > -1) {
      return stat.substring(3);
    }
    return stat;
  };

  const parseDate = (date) => (date.substring(0, date.indexOf('T')));

  const changeColor = (color, amt) => (`${color}${amt.toString(16)}`);

  const colorStatus = () => {
    switch (request.data.Status) {
      case '1. Requested':
        return ['#8fbc8f', 30];
      case '2. Awaiting Material':
        return ['#b8860b', 40];
      case '3. Awaiting Scheduling':
        return ['#fafad2', 80];
      case '4. In Progress':
        return ['#6495ed', 45];
      case '5. On Hold':
        return ['#cd5c5c', 45];
      case '6. Complete':
        return ['#00ff7f', 35];
      case '7. Abandonded':
        return ['#808080', 50];
      default:
        return ['#b0c4de', 35];
    }
  };

  const pdfTheme = createTheme({
    components: {
      MuiGrid: {
        styleOverrides: {
          root: {
            '&.pdf-': {
              padding: '16px',
              backgroundColor: colorStatus()[0],
              fontSize: '20px',
              fontWeight: 'bold'
            },
            '&.pdf-sectionHeader': {
              fontSize: '19px',
              padding: '10px 16px',
              backgroundColor: 'whitesmoke'
            },
            '&.pdf-leftBox': {
              backgroundColor: (changeColor(colorStatus()[0], colorStatus()[1])),
              padding: '8px 16px',
              display: 'flex',
              alignItems: 'center',
            },
            '&.pdf-rightBox': {
              padding: '8px 16px',
              textAlign: 'center',
              display: 'inline-grid',
              alignItems: 'center',
            },
            '&.pdf-infoBox': {
              border: '1px solid #aeaeae',
            },
            '&.pdf-potentialMultilineContainers': {
              height: '100%'
            },
            '&.pdf-subSectionHeader': {
              padding: '8px 16px',
              fontSize: '18px'
            },
            '&.pdf-lineDivider': {
              border: '1px double',
              borderColor: colorStatus()[0],
              margin: '4px 16px 8px 16px',
              width: '100%'
            },
            '&.pdf-subLineDivider': {
              border: '0.5px dotted',
              borderColor: 'light gray',
              margin: '0px 32px 4px 32px',
              width: '100%'
            }

          }
        }
      },
    }
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={pdfTheme}>
        <Paper variant="outlined" square style={{ width: '816px' }}>
          <Grid container>
            <Grid item xs={12} className='pdf-banner'>{`Order ${request.data.OrderNumber} Overview - ${request.data.BriefDescription}`}</Grid>
            <Grid container>
              <Grid item xs={12} className='pdf-sectionHeader'>
                <Grid container>
                  <Grid item xs={6}>Order Basics</Grid>
                  <Grid item xs={6} style={{ textAlign: 'right', alignSelf: 'center', color: parseStatus(request.data.Status) === 'Awaiting Scheduling' ? 'black' : colorStatus()[0] }}>
                    {`Status: ${parseStatus(request.data.Status)}`}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className='pdf-infoBox'>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={6} className='pdf-leftBox'>Order Number:</Grid>
                  <Grid item xs={6} className='pdf-rightBox'>{`${request.data.OrderNumber}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className='pdf-infoBox'>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={6} className='pdf-leftBox'>Percent Complete:</Grid>
                  <Grid item xs={6} className='pdf-rightBox'>{`${request.data.PercentComplete === '' ? '0' : request.data.PercentComplete}%`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className='pdf-infoBox'>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={3} className='pdf-leftBox'>Job:</Grid>
                  <Grid item xs={9} className='pdf-rightBox'>{`${typeof request.data.JobNumberDisplay === 'undefined' || request.data.JobNumberDisplay === '' ? 'None currently specified.' : request.data.JobNumberDisplay}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className='pdf-infoBox'>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={3} className='pdf-leftBox'>PI.CC:</Grid>
                  <Grid item xs={9} className='pdf-rightBox'>{`${typeof request.data.PiccDisplay === 'undefined' || request.data.PiccDisplay === '' ? 'None currently specified.' : request.data.PiccDisplay}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} className='pdf-infoBox'>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={3} className='pdf-leftBox'>Shared With:</Grid>
                  <Grid item xs={9} className='pdf-rightBox'>{`${typeof request.data.SharedWith === 'undefined' || request.data.SharedWith.length === 0 ? 'None.' : request.data.SharedWith.join(', ')}`}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className='pdf-sectionHeader'>Order Details</Grid>
              <Grid item xs={12} md={6} className='pdf-infoBox'>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={6} className='pdf-leftBox'>Total Quantity:</Grid>
                  <Grid item xs={6} className='pdf-rightBox'>{` ${request.data.TotalQuantity}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className='pdf-infoBox'>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={6} className='pdf-leftBox'>Type of Work:</Grid>
                  <Grid item xs={6} className='pdf-rightBox'>{` ${typeof request.data.TypeOfWork === 'undefined' || request.data.TypeOfWork === '' ? 'None currently specified.' : request.data.TypeOfWork}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className='pdf-infoBox'>
                <Grid container>
                  <Grid item xs={12} className='pdf-subSectionHeader'>Scope of Work:</Grid>
                  <div className='pdf-lineDivider' />
                  <Grid item xs={12} style={{ padding: '0px 16px 8px 32px' }}>{` ${request.data.ScopeOfWork}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center', padding: '8px' }}>{request.data.ApprovedByPreFabProjectTeam || request.data.ApprovedByPreFabProjectTeam === 'Yes' ? <i>This project has been approved by the PreFab Project Team!</i> : <b>This project has NOT yet been approved by the PreFab Project Team.</b>}</Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className='pdf-sectionHeader'>PreFab Details</Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12} className='pdf-infoBox'>
                    <Grid container>
                      <Grid item xs={9} className='pdf-leftBox'>Bill of Material Complete?</Grid>
                      <Grid item xs={3} className='pdf-rightBox'>{` ${request.data?.BillOfMaterialComplete === 'Yes' ? 'Yes' : 'No'}`}</Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className='pdf-infoBox'>
                    <Grid container>
                      <Grid item xs={9} className='pdf-leftBox'>Made in America Requirement?</Grid>
                      <Grid item xs={3} className='pdf-rightBox'>{` ${request.data?.MadeInAmericaRequirement === 'Yes' ? 'Yes' : 'No'}`}</Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className='pdf-infoBox'>
                    <Grid container>
                      <Grid item xs={9} className='pdf-leftBox'>Material Ordered?</Grid>
                      <Grid item xs={3} className='pdf-rightBox'>{` ${request.data?.MaterialOrdered === 'Yes' ? 'Yes' : 'No'}`}</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={12} className='pdf-infoBox'>
                    <Grid container className='pdf-potentialMultilineContainers'>
                      <Grid item xs={7} className='pdf-leftBox'>Hours Budgeted For PreFab:</Grid>
                      <Grid item xs={5} className='pdf-rightBox'>{`${request.data?.HoursBudgetedForPreFab}`}</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className='pdf-sectionHeader'>Delivery Details</Grid>
              <Grid item xs={12} md={6} className='pdf-infoBox'>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={6} className='pdf-leftBox'>PreFab Completed By:</Grid>
                  <Grid item xs={6} className='pdf-rightBox'>{` ${request.data.DateNeededBy}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className='pdf-infoBox'>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={6} className='pdf-leftBox'>Estimated Ship Date:</Grid>
                  <Grid item xs={6} className='pdf-rightBox'>{`${typeof request.data.EstimatedShipDate === 'undefined' || request.data.EstimatedShipDate === '' ? 'None currently specified.' : request.data.EstimatedShipDate}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className='pdf-infoBox'>
                <Grid container>
                  <Grid item xs={4} className='pdf-leftBox'>Delivery Schedule:</Grid>
                  <Grid item xs={8} style={{ textAlign: 'left' }} className='pdf-rightBox'>{` ${request.data.DeliverySchedule === '' ? 'None currently specified.' : request.data.DeliverySchedule}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className='pdf-infoBox'>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={6} className='pdf-leftBox'>Completed Build Quantity:</Grid>
                  <Grid item xs={6} className='pdf-rightBox'>{` ${typeof request.data.CompletedBuildQuantity === 'undefined' ? '0' : request.data.CompletedBuildQuantity}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className='pdf-infoBox'>
                <Grid container className='pdf-potentialMultilineContainers'>
                  <Grid item xs={6} className='pdf-leftBox'>PO Number:</Grid>
                  <Grid item xs={6} className='pdf-rightBox'>{`${typeof request.data.PoNumber === 'undefined' || request.data.PoNumber === '' ? 'None currently specified.' : request.data.PoNumber}`}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className='pdf-infoBox'>
                <Grid container>
                  <Grid item xs={4} className='pdf-leftBox'>Completed Build Storage Location:</Grid>
                  <Grid item xs={8} style={{ textAlign: 'left' }} className='pdf-rightBox'>{` ${typeof request.data.CompletedBuildStorageLocation === 'undefined' ? 'None currently specified.' : request.data.CompletedBuildStorageLocation}`}</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} className='pdf-sectionHeader'>Notes</Grid>
              {request.data.PublicNotes.length > 0 ? (
                <>
                  <Grid item xs={12} className='pdf-subSectionHeader'>Public Notes</Grid>
                  <div className='pdf-lineDivider' />
                  {request.data?.PublicNotes.map((note) => (
                    <Grid container key={note.id}>
                      <Grid item xs={8} style={{ padding: '0px 16px 8px 32px' }}>{note.body}</Grid>
                      <Grid item xs={4}>
                        <Grid container style={{ padding: '0px 16px 8px 32px' }}>
                          <Grid item xs={12}> {note.mby}</Grid>
                          <Grid item xs={12}> {parseDate(note.mdt)}</Grid>
                        </Grid>
                      </Grid>
                      <div className='pdf-subLineDivider' />

                    </Grid>
                  ))}
                </>
              ) : null}
              {request.data.PrivateNotes.length > 0 ? (
                <>
                  <Grid item xs={12} className='pdf-subSectionHeader'>Private Notes</Grid>
                  <div className='pdf-lineDivider' />
                  {request.data?.PrivateNotes.map((note) => (
                    <Grid container key={note.id}>
                      <Grid item xs={8} style={{ padding: '0px 16px 8px 32px' }}>{note.body}</Grid>
                      <Grid item xs={4}>
                        <Grid container style={{ padding: '0px 16px 8px 32px' }}>
                          <Grid item xs={12}> {note.mby}</Grid>
                          <Grid item xs={12}> {parseDate(note.mdt)}</Grid>
                        </Grid>
                      </Grid>
                      <div className='pdf-subLineDivider' />

                    </Grid>
                  ))}
                </>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

RequestPDF.propTypes = {
  request: PropTypes.objectOf(PropTypes.any).isRequired
};

export default RequestPDF;
