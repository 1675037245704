/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, {
  useEffect, useState, useContext, useRef
} from 'react';
import { CardHeader, Grid } from '@mui/material';

import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { collection, getDoc, onSnapshot, setDoc, doc, getDocs, query, where } from 'firebase/firestore';
import { existsWithLength, isUndefined, useFunctionCall } from '@aldridge/aldg-helpers';
import { AldgDialog } from '@aldridge/aldg-ui-components';
import dayjs from 'dayjs';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';
import RequestBreadcrumb from './components/request/Breadcrumb';
import StatusButtons from './components/request/StatusButtons';
import PdfView from './components/request/PdfView';
import OrderInformation from './components/request/OrderInformation';
import MaterialDeliveryInformation from './components/request/MaterialDeliveryInformation';
import OrderCompletionInformation from './components/request/OrderCompletionInformation';
import { errorCheck, SendStatusEmail, SendJPACEmail } from '../_helpers/errorCheck';

const RequestForm = (props) => {
  const { match, history } = props;
  const user = useContext(UserContext);
  const previewPDFref = useRef(null);

  const [request, setRequest] = useState({
    id: '',
    SharedWith: [],
    CreatedDate: '',
    CreatedBy: '',
    ModifiedDate: '',
    ModifiedBy: '',
    data: {
      OrderNumber: '',
      isWorkOrder: false,
      Status: 'Draft',
      JobNumber: [],
      GLCode: '',
      JobNumberDisplay: '',
      Picc: '',
      DistributionList: [],
      DateNeededBy: '',
      TypeOfWork: '',
      BriefDescription: '',
      ScopeOfWork: '',
      TotalQuantity: '',
      HoursBudgetedForPreFab: '',
      PreFabEstimatedHours: '',
      MadeInAmericaRequirement: '',
      BillOfMaterialComplete: '',
      MaterialOrdered: '',
      PoNumber: '',
      YardLinkLocation: '',
      BuildComplete: '',
      EstimatedShipDate: '',
      PercentComplete: '',
      PercentCompleteUpdated: '',
      DeliverySchedule: '',
      ApprovedByPreFabProjectTeam: '',
      PublicNotes: [],
      PrivateNotes: [],
      NewJPACLineNeeded: '',
      JPACLineItem: ''
    }
  });

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPrev, setShowPrev] = useState(false);
  const [adminRoles, setAdminRoles] = useState([]);

  const [commentModal, setCommentModal] = useState(false);
  const [approvalModal, setApprovalModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [userModal, setUserModal] = useState(false);

  const [StatusUpdated, setStatusUpdated] = useState(false);
  const [OriginalStatus, setOriginalStatus] = useState('');
  // eslint-disable-next-line
  const { data: emailData, error, loading: emailLoading, functionCall: sendEmail } = useFunctionCall('SendEmail', {});

  useEffect(() => {
    const RECORDID = (() => {
      if (match.params.id === 'new') {
        return (doc(collection(firestore, 'RequestsOrders'))).id;
      }
      return match.params.id;
    })();

    try {
      setLoading(true);
      onSnapshot(doc(firestore, 'RequestsOrders', RECORDID), (snap) => {
        getDocs(query(collection(firestore, 'PreFabTeam'), where('Role', '==', 'Admin'))).then(data => {
          const all = [];
          data.forEach(d => all.push(d.data()));
          setAdminRoles(all);
          if (snap.exists()) {
            const record = snap.data();
            if (isUndefined(record.data.Notifications)) {
              record.data.Notifications = {};
              all.forEach(admin => {
                record.data.Notifications[admin.Lead[0]] = false;
              });
            }
            setOriginalStatus(record.data.Status);
            setRequest(record);
          } else {
            const record = { ...request, id: RECORDID };
            record.SharedWith = [user.email];
            if (isUndefined(record.data.Notifications)) {
              record.data.Notifications = {};
              all.forEach(admin => {
                record.data.Notifications[admin.Lead[0]] = false;
              });
            }
            setOriginalStatus(record.data.Status);
            setRequest(record);
          }
          setLoading(false);
        });
      }, (err) => {
        toast.error(err.message);
      });
    } catch (err) {
      toast.error(err.message);
    }
  }, []);

  const handleSendComments = (privateEmail = false) => {
    setCommentModal(true);
  };
  const handleApproval = () => {
    setApprovalModal(true);
  };
  const handleRejection = () => {
    setRejectModal(true);
  };
  const handleNewUser = () => {
    setUserModal(true);
  };

  const setOrderNumber = async (rec) => {
    if (typeof rec.data.OrderNumber !== 'undefined' && rec.data.OrderNumber.trim() === '') {
      try {
        const date = (new Date());
        const month = (date.getMonth() + 1);
        const year = (date.getFullYear() + (month >= 4 ? 1 : 0)).toString();
        const orderNumber = await getDoc(doc(firestore, 'OrderNumbers', year));
        const orderNumberRecord = await (async () => {
          if (!orderNumber.exists()) {
            setDoc(doc(firestore, 'OrderNumbers', year), { order: 1 }, { merge: true });
            return { order: 1 };
          }
          return orderNumber.data();
        })();
        rec.data.OrderNumber = `${year.slice(-2)}-${(`00000000${orderNumberRecord.order.toString()}`).slice(-4)}`;
        setDoc(doc(firestore, 'RequestsOrders', rec.id), rec, { merge: true });
        setDoc(doc(firestore, 'OrderNumbers', year), { order: orderNumberRecord.order + 1 }, { merge: true });
      } catch (err) {
        sendEmail({ to: 'tgibbs@aldridgegroup.com', subject: 'OTF - Failed to Set Order Number', body: `Failed to set Order Number for record ID: ${request.id}. error message: ${err.message}` });
      }
    }
  };

  const handleChange = (event, name, displayFromTypeahead, fullRecord) => {
    try {
      const id = name || event.target.name;
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRequest = { ...request };
      changedRequest.data[id] = value;
      if (typeof displayFromTypeahead !== 'undefined') {
        changedRequest.data[`${id}Display`] = displayFromTypeahead;
      }

      if (name === 'Assembly') {
        if (typeof fullRecord !== 'undefined') {
          changedRequest.data.SubAssemblies = fullRecord[0].data.SubAssemblies;
        }
      }
      console.log(id, 'old', OriginalStatus, 'new', changedRequest.data.Status);
      if (id === 'Status' && OriginalStatus !== value) {
        console.log('entered');
        setStatusUpdated(true);
      }
      const validateNotifications = () => {
        const UsersToGetNotifications = [...adminRoles.map(r => r.Lead[0]), ...changedRequest.data.SharedWith || [], ...changedRequest.data.PreFabLead || [], ...changedRequest.data.PreFabTeam || []];
        const existingNotifications = Object.keys(changedRequest.data.Notifications);
        // remove notifications for users that are no longer on the list
        existingNotifications.forEach(n => {
          if (!UsersToGetNotifications.includes(n)) {
            delete changedRequest.data.Notifications[n];
          }
        });
      };
      if (name === 'SharedWith') {
        changedRequest[id] = value;
        value.forEach(v => {
          if (!existsWithLength(changedRequest.data.Notifications[v])) {
            changedRequest.triggerEmailAlertNewUser = true;
            changedRequest.data.Notifications[v] = true;
          }
        });
        validateNotifications();
      } else {
        changedRequest.data[id] = value;
      }

      if (id === 'PreFabLead' || id === 'PreFabTeam') {
        value.forEach(v => {
          if (!existsWithLength(changedRequest.data.Notifications[v])) {
            changedRequest.triggerEmailAlertNewUser = true;
            changedRequest.data.Notifications[v] = true;
          }
        });
        validateNotifications();
      }

      if (changedRequest.CreatedDate === '') {
        changedRequest.CreatedBy = user.email;
        changedRequest.CreatedDate = new Date().toJSON();
      }
      if (id === 'IsGLTicket') {
        if (value === '') {
          changedRequest.data.GLCode = '';
          changedRequest.data.DepartmentNumberDisplay = '';
          changedRequest.data.DepartmentNumber = [];
        } else {
          changedRequest.data.JobNumberDisplay = '';
          changedRequest.data.JobNumber = [];
          changedRequest.data.PiccDisplay = '';
          changedRequest.data.Picc = [];
        }
      }
      if (id === 'PublicNotes') {
        changedRequest.triggerEmailAlert = true;
        Object.keys(changedRequest.data.Notifications).forEach(u => {
          changedRequest.data.Notifications[u] = true;
        });
        adminRoles.forEach(u => {
          changedRequest.data.Notifications[u.Lead[0]] = true;
        });
      }
      if (id === 'PrivateNotes') {
        changedRequest.triggerPrivateEmailAlert = true;
        const PreFabTeams = [...changedRequest.data.PreFabLead || [], ...changedRequest.data.PreFabTeam || []];
        PreFabTeams.forEach(u => {
          changedRequest.data.Notifications[u] = true;
        });
        adminRoles.forEach(u => {
          changedRequest.data.Notifications[u.Lead[0]] = true;
        });
      }

      if (id === 'PercentComplete') {
        changedRequest.data.PercentCompleteUpdated = new Date().toJSON();
      }

      changedRequest.ModifiedBy = user.email;
      changedRequest.ModifiedDate = new Date().toJSON();
      delete changedRequest.data.ArrowOpenDisplay;

      if ((id === 'PrivateNotes' || id === 'PublicNotes') && changedRequest.id !== '') {
        setCommentModal(true);
        // setDoc(doc(firestore, 'RequestsOrders', changedRequest.id), changedRequest, { merge: true }).then(() => {
        //   toast.success('Record and Notes Saved Successfully.', { autoClose: 2000 });
        // });
      }
      setRequest(changedRequest);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const SendPublicCommentEmail = (data) => {
    const html = `
    <table style="border-spacing:0;border-collapse:collapse;padding:0;vertical-align:top;text-align:left;border:1px solid #ddd;width:100%">
    <tr>
      <td colspan="2" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">UPDATE</td>
      <td colspan="2">Comment Added</td>
    </tr>
      <tr>
        <td colspan="4" valign="top" style="font-size:16pt;font-weight:bold;text-align:left;color:#ffffff;background-color:#2c3f50;padding:0.5rem">
          <div style="margin-left:0.000px">${['Approved', '4. In Progress', '5. On Hold', '6. Complete'].indexOf(data.data.Status) > -1 ? 'Order' : 'Request'} Information</div>
        </td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Order Number</td>
        <td>${data.data.OrderNumber || ''}</td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Brief Description of Scope</td>
        <td colspan="3">${data.data.BriefDescription || ''}</td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Status</td>
        <td>${data.data.Status}</td>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Job Number</td>
        <td>${data.data.JobNumberDisplay}</td>
      </tr>
      ${Array.isArray(data.data.PublicNotes) ? `<tr>
        <td colSpan="4" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Notes</td>
      </tr>
      <tr>
        <td colspan="2" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Comment</td>
        <td style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Modified Date</td>
        <td style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Modified By</td>
      </tr>
      ${data.data.PublicNotes.map((note, idx) => `<tr style="background-color:${idx % 2 ? '#efefef' : 'white'};">
        <td style="text-align:left;padding:0.5rem" colspan="2">${note.body}</td>
        <td style="text-align:left;padding:0.5rem">${dayjs(note.mdt).tz('America/Chicago').format('llll')} CST</td>
        <td style="text-align:left;padding:0.5rem">${note.mby}</td>
        </tr>`).join('')}` : ''}
    </table>
    <a href='${window.location.href}'>
      <div style='font-size:12pt;font-weight:bold;color:#fff;background-color:#4285f4;text-align:center;border-radius: 0.15rem;width: fit-content;margin: 0.5rem 0;padding: 0.5rem 0.75rem;'>Reply in App</div>
    </a>
`;
    const subject = ['4. In Progress', '6. Complete', 'Approved', '5. On Hold', '2. Awaiting Material', '3. Awaiting Scheduling'].indexOf(data.data.Status) > -1 ? `Order Number ${data.data.OrderNumber}` : `PreFab Request for Job ${data.data.JobNumberDisplay}`;
    let to = "";
    to = [...data.SharedWith || [], ...data.data.PreFabLead || [], ...data.data.PreFabTeam || [], ...adminRoles.map(r => r.Lead[0])].filter(r => existsWithLength(r));

    console.log(to);
    sendEmail({ to, subject: `${subject} - New Comment Added`, html });
    // sendEmail({ to: 'itdevelopment@aldridge-electric.com', subject, html });
  };
  const SendPrivateCommentEmail = (data) => {
    const html = `
    <table style="border-spacing:0;border-collapse:collapse;padding:0;vertical-align:top;text-align:left;border:1px solid #ddd;width:100%">
    <tr>
      <td colspan="2" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">UPDATE</td>
      <td colspan="2">Comment Added</td>
    </tr>
      <tr>
        <td colspan="4" valign="top" style="font-size:16pt;font-weight:bold;text-align:left;color:#ffffff;background-color:#2c3f50;padding:0.5rem">
          <div style="margin-left:0.000px">${['Approved', '4. In Progress', '5. On Hold', '6. Complete'].indexOf(data.data.Status) > -1 ? 'Order' : 'Request'} Information</div>
        </td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Order Number</td>
        <td>${data.data.OrderNumber || ''}</td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Brief Description of Scope</td>
        <td colspan="3">${data.data.BriefDescription || ''}</td>
      </tr>
      <tr>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Status</td>
        <td>${data.data.Status}</td>
        <td valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Job Number</td>
        <td>${data.data.JobNumberDisplay}</td>
      </tr>
      ${Array.isArray(data.data.PrivateNotes) ? `<tr>
        <td colSpan="4" valign="top" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Notes</td>
      </tr>
      <tr>
        <td colspan="2" style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Comment</td>
        <td style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Modified Date</td>
        <td style="font-size:12pt;font-weight:bold;text-align:left;padding:0.5rem">Modified By</td>
      </tr>
      ${data.data.PrivateNotes.map((note, idx) => `<tr style="background-color:${idx % 2 ? '#efefef' : 'white'};">
        <td style="text-align:left;padding:0.5rem" colspan="2">${note.body}</td>
        <td style="text-align:left;padding:0.5rem">${dayjs(note.mdt).tz('America/Chicago').format('llll')} CST</td>
        <td style="text-align:left;padding:0.5rem">${note.mby}</td>
        </tr>`).join('')}` : ''}
    </table>
    <a href='${window.location.href}'>
      <div style='font-size:12pt;font-weight:bold;color:#fff;background-color:#4285f4;text-align:center;border-radius: 0.15rem;width: fit-content;margin: 0.5rem 0;padding: 0.5rem 0.75rem;'>Reply in App</div>
    </a>
`;
    const subject = ['4. In Progress', '6. Complete', 'Approved', '5. On Hold', '2. Awaiting Material', '3. Awaiting Scheduling'].indexOf(data.data.Status) > -1 ? `Order Number ${data.data.OrderNumber}` : `PreFab Request for Job ${data.data.JobNumberDisplay}`;
    let to = "";

    to = [...data.data.PreFabLead || [], ...data.data.PreFabTeam || [], ...adminRoles.map(r => r.Lead[0])].filter(r => existsWithLength(r));
    console.log(to);
    sendEmail({ to, subject: `${subject} - New PreFab Comment Added`, html });
    // sendEmail({ to: 'itdevelopment@aldridge-electric.com', subject, html });
  };

  const approveRecord = async (approved, record) => {
    if (approved) {
      setSaving(true);
      const rec = { ...record };
      const errors = await errorCheck(record, true);
      if (errors.length === 0) {
        rec.data.Status = 'Approved';
        rec.data.isWorkOrder = true;
        rec.ModifiedBy = user.email;
        rec.ModifiedDate = new Date().toJSON();

        await setOrderNumber(rec);

        if (rec.data.JPACLineItem === 'No' && rec.data.NewJPACLineNeeded === 'Yes') {
          SendJPACEmail(sendEmail, rec);
        }

        // SendStatusEmail(sendEmail, rec, adminRoles.map(r => r.Lead[0]));
      } else {
        toast.error(<div>There are errors in this record, please correct them before approving the request.<br />Fields with missing data: <strong>{errors.join(',')}</strong></div>);
      }
      setSaving(false);
    }
    setApprovalModal(false);
  };

  const rejectRecord = async (rejected, record) => {
    setSaving(true);
    if (rejected) {
      const rec = { ...record };
      rec.data.Status = 'Rejected';
      rec.data.isWorkOrder = false;
      rec.ModifiedBy = user.email;
      rec.ModifiedDate = new Date().toJSON();
      await setDoc(doc(firestore, 'RequestsOrders', rec.id), rec, { merge: true });
      SendStatusEmail(sendEmail, rec, adminRoles.map(r => r.Lead[0]));
    }
    setRejectModal(false);
    setSaving(false);
  };

  const newUserRecord = async (rejected, record) => {
    setSaving(true);
    if (rejected) {
      const rec = { ...record };
      delete rec.triggerEmailAlertNewUser;
      await setDoc(doc(firestore, 'RequestsOrders', rec.id), rec, { merge: true });
      SendStatusEmail(sendEmail, rec, adminRoles.map(r => r.Lead[0]));
    } else {
      const rec = { ...record };
      delete rec.triggerEmailAlertNewUser;
      await setDoc(doc(firestore, 'RequestsOrders', rec.id), rec);
    }
    setUserModal(false);
    setSaving(false);
  };

  const sendComments = async (send, record) => {
    setSaving(true);
    if (send) {
      const rec = { ...record };
      const publicEmail = rec.triggerEmailAlert;
      const privateEmail = rec.triggerPrivateEmailAlert;
      delete rec.triggerEmailAlert;
      delete rec.triggerPrivateEmailAlert;
      await setDoc(doc(firestore, 'RequestsOrders', rec.id), rec);
      if (publicEmail) {
        SendPublicCommentEmail(rec);
      }
      if (privateEmail) {
        SendPrivateCommentEmail(rec);
      }
    } else {
      const rec = { ...record };
      delete rec.triggerEmailAlert;
      delete rec.triggerPrivateEmailAlert;
      await setDoc(doc(firestore, 'RequestsOrders', rec.id), rec);
    }
    setCommentModal(false);
    setSaving(false);
  };

  const userState = [
    {
      type: 'text',
      content: 'The record has had new users associated.',
      key: 'header'
    },
    {
      type: 'text',
      content: 'Would you like to send an email to the users?',
      key: 'question'
    },
    {
      type: 'actions',
      buttons: [
        {
          text: 'No',
          onClick: () => {
            newUserRecord(false, request);
          },
          color: 'error',
        },
        {
          text: 'Send',
          onClick: () => {
            newUserRecord(true, request);
          },
        },
      ],
      key: 'ex. buttons',
    },
  ];
  const commentState = [
    {
      type: 'text',
      content: 'The record has had the comments modified.',
      key: 'header'
    },
    {
      type: 'text',
      content: 'Would you like to send an email to the team with your comments?',
      key: 'question'
    },
    {
      type: 'actions',
      buttons: [
        {
          text: 'No',
          onClick: () => {
            sendComments(false, request);
          },
          color: 'error',
        },
        {
          text: 'Send',
          onClick: () => {
            sendComments(true, request);
          },
        },
      ],
      key: 'ex. buttons',
    },
  ];
  const approvalState = [
    {
      type: 'text',
      content: 'Do you want to approve this request?',
      key: 'header'
    },
    {
      type: 'actions',
      buttons: [
        {
          text: 'Cancel',
          onClick: () => {
            approveRecord(false, request);
          },
          color: 'error',
        },
        {
          text: 'Approve',
          onClick: () => {
            approveRecord(true, request);
          },
        },
      ],
      key: 'ex. buttons',
    },
  ];
  const rejectState = [
    {
      type: 'text',
      content: 'Do you want to reject this request?',
      key: 'header'
    },
    {
      type: 'actions',
      buttons: [
        {
          text: 'Cancel',
          onClick: () => {
            rejectRecord(false, request);
          },
          color: 'error',
        },
        {
          text: 'Reject',
          onClick: () => {
            rejectRecord(true, request);
          },
        },
      ],
      key: 'ex. buttons',
    },
  ];

  const viewPrev = () => {
    setShowPrev(!showPrev);
  };

  const downloadPrev = () => {
    if (previewPDFref.current) {
      previewPDFref.current.save();
    }
  };

  const submitToastId = useRef(null);

  useEffect(() => {
    if (saving) {
      submitToastId.current = toast('Submitting Record', {
        position: 'top-center',
        autoClose: false
      });
    } else if (!saving && submitToastId.current !== null) {
      toast.dismiss(submitToastId.current);
    }
  }, [saving]);


  const hasEditAccess = request.SharedWith.indexOf(user.email) > -1 || user.admin || user.prefab || user.lead;
  const disabledForm = (['Draft', 'Rejected'].indexOf(request.data.Status) === -1 && !user.admin && !user.lead);

  console.log(request);
  return (
    <>
      {
        saving ? (
          <div style={{
            display: 'block', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#efefef', opacity: 0.5, zIndex: 300
          }}
          />
        ) : null
      }
      <AldgDialog
        title="Alert New User(s)?"
        pieces={userState}
        open={userModal}
        fullWidth
        maxWidth="md"
      />
      <AldgDialog
        title="Send Comments?"
        pieces={commentState}
        open={commentModal}
        fullWidth
        maxWidth="md"
      />
      <AldgDialog
        title="Approve Request?"
        pieces={approvalState}
        open={approvalModal}
        fullWidth
        maxWidth="md"
      />
      <AldgDialog
        title="Reject Request?"
        pieces={rejectState}
        open={rejectModal}
        fullWidth
        maxWidth="md"
      />
      <RequestBreadcrumb request={request} user={user} />
      <Grid container style={{ backgroundColor: 'white', padding: '12px' }}>
        <CardHeader title='Order Request Form' style={{ width: '100%' }} />
        {emailLoading ?
          <Grid container justifyContent="center"><TailSpin width={45} /></Grid> :
          <StatusButtons
            user={user}
            request={request}
            match={match}
            history={history}
            setSaving={setSaving}
            showPrev={showPrev}
            setOrderNumber={setOrderNumber}
            viewPrev={viewPrev}
            downloadPrev={downloadPrev}
            handleSendComments={handleSendComments}
            handleNewUser={handleNewUser}
            handleApproval={handleApproval}
            handleRejection={handleRejection}
            adminRoles={adminRoles}
            StatusUpdated={StatusUpdated}
            setStatusUpdated={setStatusUpdated}
          />
        }
        {
          loading
            ? (
              <div style={{
                display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
              }}
              >
                <TailSpin
                  type="TailSpin"
                  color="#3b78e7"
                  height={100}
                  width={100}
                />
                <div>Loading Request Data...</div>
              </div>
            )
            : (
              <Grid container>
                <PdfView showPrev={showPrev} request={request} previewPDFref={previewPDFref} />
                {
                  showPrev ? null :
                    <Grid item xs={12}>
                      <OrderInformation
                        request={request}
                        hasEditAccess={hasEditAccess}
                        disabledForm={disabledForm}
                        handleChange={handleChange}
                        user={user}
                      />
                      <MaterialDeliveryInformation
                        request={request}
                        hasEditAccess={hasEditAccess}
                        disabledForm={disabledForm}
                        handleChange={handleChange}
                        user={user}
                      />
                      <OrderCompletionInformation
                        request={request}
                        hasEditAccess={hasEditAccess}
                        disabledForm={disabledForm}
                        handleChange={handleChange}
                        user={user}
                      />
                    </Grid>
                }
              </Grid>
            )
        }
      </Grid>
    </>
  );
};

RequestForm.propTypes = {
  history: PropTypes.shape({
    length: PropTypes.number,
    action: PropTypes.string,
    location: PropTypes.objectOf(PropTypes.string, PropTypes.object),
    push: PropTypes.func,
    replace: PropTypes.func,
    go: PropTypes.func,
    goBack: PropTypes.func,
    goForward: PropTypes.func,
    block: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.objectOf(PropTypes.any),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string
  }),
};
RequestForm.defaultProps = {
  history: {
    push: () => { }
  },
  match: {
    url: ''
  }
};

export default RequestForm;
