/* eslint-disable no-unused-vars */
import { RecoilRoot } from 'recoil';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { TailSpin } from 'react-loader-spinner';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { OfflineProvider } from '@aldridge/aldg-helpers';
import Header from './_GlobalComponents/header';
import Footer from './_GlobalComponents/footer';
import Application from './Pages/Application';
import UserProvider from './providers/UserProvider';
import OfflineDataProvider from './providers/OfflineDataProvider';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { config } from './firebase';
import ChangelogModal from './_GlobalComponents/Changelog/ChangelogModal';
import ErrorBoundary from './_GlobalComponents/ErrorBoundary';
import AldgTheme from './theme';

import './_assets/base.scss';
import 'react-toastify/dist/ReactToastify.css';

const rootElement = document.getElementById('root');

const renderApp = () => {
  const configClass = `${config.projectId.indexOf('-dev') > -1 ? 'bg-dev' : ''
    } ${config.projectId.indexOf('-training') > -1 ? 'bg-training' : ''}`.trim();

  try {
    ReactDOM.render(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={AldgTheme}>
          <RecoilRoot>
            <OfflineProvider>
              <UserProvider>
                <ErrorBoundary
                  componentName='Main Application'
                  user={{ email: '' }}
                >
                  <OfflineDataProvider>
                    <BrowserRouter>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>

                        <ToastContainer
                          position='bottom-left'
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="dark"
                        />
                        <Suspense
                          fallback={
                            <div className='loader-container'>
                              <div className='loader-container-inner'>
                                <div className='text-center'>
                                  <TailSpin />
                                </div>
                                <h6 className='mt-3'>
                                  Please wait while we load the Application
                                </h6>
                              </div>
                            </div>
                          }
                        >
                          <div
                            id='appParent'
                            className={configClass}
                            style={{
                              display: 'flex',
                              height: '100%',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                              alignItems: 'center'
                            }}
                          >
                            <ChangelogModal />
                            <Header />
                            <Application />
                            <Footer
                              copy={`${new Date().getFullYear()} Aldridge, All Rights Reserved`}
                            />
                            <ServiceWorkerWrapper />
                          </div>
                        </Suspense>
                      </LocalizationProvider>

                    </BrowserRouter>
                  </OfflineDataProvider>
                </ErrorBoundary>
              </UserProvider>
            </OfflineProvider>
          </RecoilRoot>
        </ThemeProvider>
      </StyledEngineProvider>,
      rootElement
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};
renderApp();
