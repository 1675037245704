import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { collection, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { CardContent, CardHeader, Grid } from '@mui/material';
import { AldgDataGrid, InputRadio, InputSelect, InputText } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { toast } from 'react-toastify';
import { firestore } from '../firebase';
import { UserContext } from '../providers/UserProvider';


const Assembly = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const user = useContext(UserContext);
  const [updatePromises, setUpdatePromises] = useState([]);
  const [Record, setRecord] = useState({
    id: "",
    CreatedDate: "",
    CreatedBy: "",
    ModifiedDate: "",
    ModifiedBy: "",
    data: {
      Assembly: '',
      TypeOfWork: '',
      SubAssemblies: []
    }
  });

  useEffect(() => {
    if (match.params.id !== 'new') {
      onSnapshot(doc(firestore, `Assembly/${match.params.id}`), snap => {
        setRecord(snap.data());
      });
    }
  }, []);

  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const nameCanSplit = (n) => {
        if (n.split('.').length > 1) return [true, n.split('.')];
        return [false, n];
      };
      const [canSplit, id] = typeof name !== 'undefined' ? nameCanSplit(name) : nameCanSplit(event.target.name);
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...Record };
      if (changedRecord.CreatedBy === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      if (canSplit) {
        changedRecord.data[id[0]][id[1]] = value;
      } else {
        changedRecord.data[id] = value;
      }

      if (typeof displayFromTypeahead !== 'undefined') {
        if (canSplit) {
          changedRecord.data[id[0]][`${id[1]}Display`] = displayFromTypeahead;
        } else {
          changedRecord.data[`${id}Display`] = displayFromTypeahead;
        }
      }
      setRecord(changedRecord);
    } catch (err) {
      /* toast.error(err.message); */
    }
  };
  const columns = [
    {
      field: 'Order',
      inputType: 'InputNumber',
      canEdit: true,
      flex: 20
    },
    {
      field: 'SubAssembly',
      inputType: 'InputText',
      canEdit: true,
      flex: 20
    }
  ];

  useEffect(() => {
    if (updatePromises.length > 0) {
      const uRecord = JSON.parse(JSON.stringify(Record));
      const ogRecords = JSON.parse(JSON.stringify(uRecord.data.SubAssemblies));
      const removeRecords = updatePromises.filter(r => r.operation !== 'set').map(r => r.id);
      const updateRecords = updatePromises.filter(r => r.operation === 'set').map(r => r.row);
      const newSet = ogRecords.filter(r => !removeRecords.includes(r.id));
      updateRecords.forEach(ur => {
        const idx = newSet.findIndex(ns => ns.id === ur.id);
        if (idx > -1) {
          newSet[idx] = ur;
        } else {
          newSet.push(ur);
        }
      });
      uRecord.data.SubAssemblies = newSet;
      setRecord(uRecord);
      setUpdatePromises([]);
    }
  }, [updatePromises]);

  useEffect(() => {
    if (Record.data.SubAssemblies.length > 1) {
      setRecord(uPrev => {
        const subs = JSON.parse(JSON.stringify(uPrev.data.SubAssemblies));
        uPrev.data.SubAssemblies = subs.sort((a, b) => a.Order - b.Order);
        return uPrev;
      });
    }
  }, [Record.data.SubAssemblies]);

  const handleSave = () => {
    try {
      if (Record.id === '') {
        const docRef = doc(collection(firestore, 'Assembly'));
        Record.id = docRef.id;
        setDoc(docRef, Record, { merge: true }).catch((err) => {
          toast.error(err);
        });
      } else {
        const docRef = doc(collection(firestore, 'Assembly'), Record.id);
        setDoc(docRef, Record, { merge: true }).catch((err) => {
          toast.error(err);
        });
      }
    } catch (err) {
      toast.error(err.toString());
    }
    toast.success('Record saved!');
  };
  const close = () => {
    history.push('/Assembly');
  };

  return (
    <Grid container style={{ backgroundColor: 'white', padding: '12px' }}>
      <CardHeader title='Assembly' style={{ width: '100%' }} />
      <Grid container>
        <div
          style={{
            position: 'sticky',
            top: '-1px',
            left: 0,
            right: 0,
            width: '100%',
            padding: '8px',
            paddingRight: '0px',
            display: 'flex',
            justifyContent: 'flex-end',
            zIndex: 100
          }}
        >
          <ToolTipIconButton icon={faSave} color="success" onClick={handleSave} />
          <ToolTipIconButton icon={faTimes} color="error" onClick={close} />
        </div>
        <Grid item xs={12}>
          <CardContent>
            <Grid container>
              <Grid item className='aldg-field' xs={4}>
                <InputSelect
                  name="TypeOfWork"
                  label="Type of Work"
                  value={Record.data.TypeOfWork || ''}
                  onChange={onChange}
                  disableEmptyOption
                  options={[
                    { label: 'Select a Type of Work...', value: 'Select a Type of Work...', disabled: true },
                    { label: 'Welding/ Fabrication/ Jigs', value: 'Welding/ Fabrication/ Jigs' },
                    { label: 'Underground/ Ductbank', value: 'Underground/ Ductbank' },
                    { label: 'Cable/ Wire Prep', value: 'Cable/ Wire Prep' },
                    { label: 'Building Lighting', value: 'Building Lighting' },
                    { label: 'Poles/ Luminaries', value: 'Poles/ Luminaries' },
                    { label: 'Conduit bending/ Nipples/ Racks', value: 'Conduit bending/ Nipples/ Racks' },
                    { label: 'JBox Prep', value: 'JBox Prep' },
                    { label: 'Device/ Equipment Prep', value: 'Device/ Equipment Prep' },
                    { label: 'Material Kitting', value: 'Material Kitting' },
                    { label: 'Concrete Precast', value: 'Concrete Precast' },
                    { label: 'CNC', value: 'CNC' },
                    { label: 'Equipment Racks', value: 'Equipment Racks' },
                    { label: 'Carpenter', value: 'Carpenter' },
                    { label: 'R&D', value: 'R&D' },
                    { label: 'Rework', value: 'Rework' },
                    { label: 'Other', value: 'Other' }
                  ]}
                />
              </Grid>
              <Grid item className='aldg-field' xs={4}>
                <InputText
                  label="Assembly"
                  name="Assembly"
                  onChange={onChange}
                  value={Record.data.Assembly || ''}
                />
              </Grid>
              <Grid item className='aldg-field' xs={4}>
                <InputRadio
                  radios={[
                    { value: 'Active', label: 'Active' },
                    { value: 'Inactive', label: 'Inactive' }
                  ]}
                  FormControlLabelProps={{
                    label: 'Status'
                  }}
                  RadioGroupProps={{
                    name: 'Status',
                    onChange,
                    value: Record.data.Status || '',
                  }}
                />
              </Grid>
              <Grid item className='aldg-field' xs={12}>
                <AldgDataGrid
                  updateOverride={updatePromises}
                  setUpdateOverride={setUpdatePromises}
                  rows={Record.data.SubAssemblies || []}
                  columns={columns}
                  editable
                />
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Grid>
  );
};


Assembly.propTypes = {};
Assembly.defaultProps = {};


export default Assembly;