/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faMailbox } from '@fortawesome/pro-solid-svg-icons';
import { useFunctionCall } from '@aldridge/aldg-helpers';


const Test = (props) => {
  console.log(props);
  const { data, error, loading, functionCall } = useFunctionCall('SendEmail');

  const sendEmail = () => {
    functionCall({ to: 'tgibbs@aldridgegroup.com', subject: 'Test Email 2', body: 'This is a test email' });
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <ToolTipIconButton icon={faMailbox} tooltipText="Trigger Email" label="Email" onClick={sendEmail} />
      </Grid>
    </Grid>
  );

};


Test.propTypes = {};
Test.defaultProps = {};


export default Test;