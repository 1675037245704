/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

const theme = createTheme();

const iconsTheme = createTheme({
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.aldg-selectedMenuItem': {
            backgroundColor: '#eeeeee',
            backgroundImage:
              'linear-gradient(to bottom, #eeeeee 0%, #eeeeee 100%), linear-gradient(to bottom, #eeeeee 0%, #eeeeee 100%)',
            backgroundClip: 'content-box, padding-box'
          },
          '&.aldg-selectedMenuItem .MuiListItemText-primary': {
            fontWeight: 'bold'
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '& .aldg-iconContainer': {
            margin: '0 12px',
            boxShadow:
              '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            width: '40px',
            height: '40px',
            minWidth: '0',
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            backgroundColor: theme.palette.grey[400],
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: theme.palette.grey[600],
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
            },
            '&.errorContainer': {
              backgroundColor: theme.palette.error.main,
              '&:hover': {
                backgroundColor: theme.palette.error.dark
              }
            },
            '&.primaryContainer': {
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark
              }
            },
            '&.successContainer': {
              backgroundColor: theme.palette.success.main,
              '&:hover': {
                backgroundColor: theme.palette.success.dark
              }
            }
          },
          '& .aldg-buttonIconWrapper': {
            backgroundColor: 'transparent',
            padding: '0px',
            minWidth: '40px',
            maxWidth: '40px',
            minHeight: '40px',
            maxHeight: '40px'
          },
          '& .aldg-icon': {
            color: 'white',
            fontSize: '1.5rem',
            '&.aldg-icon-black': {
              color: 'black'
            },
            '&.aldg-icon-duo': {
              color: '#a5adba',
              '& g': {
                '& g': {
                  '& path': {
                    stroke: 'black',
                    strokeWidth: 20
                  }
                }
              }
            }
          }
        }
      }
    }
  }
});

const basicTheme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.aldg-tab': {
            textTransform: 'none',
            fontWeight: 'bold',
            color: 'black'
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '&.aldg-saving': {
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#efefef',
            opacity: 0.5,
            zIndex: 2500
          }
        },
        item: {
          padding: '8px 0',
          '&.aldg-field': {
            display: 'flex',
            padding: '8px',
            alignItems: 'flex-end'
          }
        },
        container: {
          '&.aldg-rootFormBox > .MuiGrid-item': {
            padding: '8px'
          }
          // padding: '8px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.aldg-formButtons': {
            position: 'sticky',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'flex-end',
            zIndex: 100
          },
          '&.aldg-formContainer': {
            border: '1px solid rgba(0, 0, 0, 0.12)'
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-footerContainer': {
            justifyContent: 'flex-start',
            marginLeft: '-12px'
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#fff'
          },
          '& .MuiDataGrid-row:nth-of-type(odd)': {
            backgroundColor: '#efefef'
          },
          '& .MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: '#fff'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#fff'
          }
        }
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '& .aldg-icon': {
            fontSize: '2rem',
            transform: 'scale(calc(5/6))'
          }
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2100 !important',
          '& .aldg-modalPaper': {
            zIndex: 1,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '80%',
            overflow: 'auto',
            width: '80%',
            padding: theme.spacing(1, 2),
            [theme.breakpoints.down('sm')]: {
              padding: '6px 8px',
              width: '100%'
            },
            '& .aldg-modalHeader': {
              position: 'sticky',
              top: '-1px',
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'space-between'
            },
            '& .aldg-closeBtn': {
              minWidth: '50px',
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              fontSize: '20px',
              textAlign: 'right',
              paddingRight: '12px',
              color: '#f50057'
            },
            '& .aldg-modalBody': {
              position: 'relative',
              flex: '1 1 auto',
              padding: '12px',
              paddingTop: '0px'
            }
          },
          '&.aldg-level2Modal': {
            zIndex: '2200 !important'
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          '&:not(:last-child)': {
            borderBottom: 0
          },
          '&$expanded': {
            border: '1px solid #2a56a7',
            margin: '8px 0',
            '& .MuiAccordionSummary-expandIcon': {
              color: 'white'
            }
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#eee',
          boxShadow: 'inset 0px -19px 4px -19px #aaa',
          color: 'inherit',
          '&$expanded': {
            maxHeight: '48px',
            minHeight: '48px',
            color: 'white',
            backgroundColor: 'rgb(59, 120, 231)',
            '& .MuiButtonBase-root': {
              color: 'white'
            },
            '& $outlined': {
              border: '2px solid white'
            }
          },
          editIcon: {
            color: 'black'
          },
          expanded: {}
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          flexDirection: 'column'
        }
      }
    }
  }
});

const AldgTheme = createTheme(deepmerge(iconsTheme, basicTheme));

export { theme };
export default AldgTheme;
